import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { IoTrophyOutline, IoGolfOutline, IoSettingsOutline, IoChevronBackOutline } from 'react-icons/io5';
import { supabase } from '../ReusableComponents/supabaseClient';
import PoolDetailsModal from './PoolDetailsModal';
import Modal from '../ReusableComponents/Modal';
import InviteToPool from './InviteToPool';
import ManagePoolEntries from './ManagePoolEntries';
import { canEditRecord } from './PoolModalHelper';
import { PacmanLoader } from "react-spinners";
import '../styles/golfPoolStyles.css';

const PoolPage = () => {
  const [pool, setPool] = useState(null);
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [showManageModal, setShowManageModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { poolId } = useParams();
  const navigate = useNavigate();
  const loggedInUser = Number(localStorage.getItem("userId"));

  const fetchPoolData = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);

      const { data: poolData, error: poolError } = await supabase
        .from('pool')
        .select(`
          id,
          name,
          owner,
          tournament_status,
          number_of_teams,
          max_players,
          num_player_used_for_scoring,
          all_players_must_make_cut,
          tie_breaker,
          current_round,
          tournament_fixture (
            tournament_id,
            season,
            start_date,
            name
          )
        `)
        .eq('id', poolId)
        .single();

      if (poolError) throw poolError;
      if (!poolData) throw new Error('Pool not found');

      setPool(poolData);

      const { data: teamsData, error: teamsError } = await supabase
        .from('team')
        .select(`
          *,
          owner_details:user!inner(
            full_name,
            id
          ),
          line_items:teamLineItem (count)
        `)
        .eq('pool', poolId)
        .order('rank', { ascending: true, nullsLast: true });

      if (teamsError) throw teamsError;

      const { data: inviteLinks, error: inviteLinksError } = await supabase
        .from('InviteLink')
        .select('*')
        .eq('pool', poolId);

      if (inviteLinksError) throw inviteLinksError;

      const teamsWithStatus = teamsData?.map(team => {
        const teamInviteLink = inviteLinks?.find(link => link.person === team.owner_details.id);
        const isActive = team.line_items[0].count === poolData.max_players && teamInviteLink?.paid;
        return {
          ...team,
          isActive
        };
      }) || [];

      const sortedTeams = teamsWithStatus.sort((a, b) => {
        if (a.rank === null) return 1;
        if (b.rank === null) return -1;
        return a.rank - b.rank;
      });

      setTeams(sortedTeams);

    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, [poolId]);

  useEffect(() => {
    fetchPoolData();

    // Subscribe to InviteLink changes
    const inviteLinkSubscription = supabase
      .channel("inviteLinkChanges")
      .on(
        "postgres_changes",
        { event: "UPDATE", schema: "public", table: "InviteLink", filter: `pool=eq.${poolId}` },
        async (payload) => {
          // Fetch latest pool data first
          const { data: poolData } = await supabase
            .from('pool')
            .select(`
              *,
              tournament_fixture (
                tournament_id,
                season,
                start_date
              )
            `)
            .eq('id', poolId)
            .single();

          // Fetch updated team data to recalculate active status
          const { data: teamsData } = await supabase
            .from('team')
            .select(`
              *,
              owner_details:user!inner(
                full_name,
                id
              ),
              line_items:teamLineItem (count)
            `)
            .eq('pool', poolId)
            .order('team_world_ranking_average', { ascending: true })
            .limit(4);

          const { data: inviteLinks } = await supabase
            .from('InviteLink')
            .select('*')
            .eq('pool', poolId);

          const teamsWithStatus = teamsData?.map(team => {
            const teamInviteLink = inviteLinks?.find(link => link.person === team.owner_details.id);
            const isActive = team.line_items[0].count === poolData.max_players && teamInviteLink?.paid;
            return {
              ...team,
              isActive
            };
          }) || [];

          const sortedTeams = teamsWithStatus.sort((a, b) => {
            if (a.rank === null) return 1;
            if (b.rank === null) return -1;
            return a.rank - b.rank;
          });

          setTeams(sortedTeams);
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(inviteLinkSubscription);
    };
  }, [poolId, fetchPoolData]);

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    return new Date(dateString).toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    });
  };

  const getStatusClass = (status) => {
    switch (status) {
      case 'Not Started':
        return 'status-not-started';
      case 'In Progress':
        return 'status-in-progress';
      case 'Suspended':
        return 'status-suspended';
      case 'Official':
      case 'Complete':
        return 'status-official';
      default:
        return 'status-not-started';
    }
  };

  if (loading) {
    return (
          <div className="golf-pools-loading-container">
            <PacmanLoader className="spinner" color="#1a472a" loading={true} size={25} aria-label="Loading Spinner" />
          </div>
    );
  }

  if (error) {
    return <div className="pool-page-error">Error: {error}</div>;
  }

  if (!pool) {
    return <div className="pool-page-error">Pool not found</div>;
  }

  return (
    <div className="golf-pools-card">
      <div className="golf-pools-container">
        <div className="golf-pools-header">
          <div className="golf-pools-header-row">
            <button onClick={() => navigate(-1)} className="golf-pools-back-link">
              <IoChevronBackOutline /> Back
            </button>
            <h1 className="golf-pools-title">{pool.name}</h1>
            <div className={`golf-pools-badge ${getStatusClass(pool.tournament_status)} on-grey`}>
              {pool.tournament_status || 'Not Started'}
            </div>
          </div>
        </div>
        
        <div className="golf-pools-actions">
          <Link to={`/teams/${poolId}`} className="golf-pools-action-button">
            <IoGolfOutline /> Team
          </Link>
          <Link to={`/leaderboard/${poolId}`} className="golf-pools-action-button">
            <IoTrophyOutline /> Leaderboard
          </Link>
          {pool.owner === loggedInUser && (
            <button 
              className="golf-pools-action-button"
              onClick={() => setShowModal(true)}
            >
              <IoSettingsOutline /> Manage
            </button>
          )}
        </div>

        <div className="stats-data-container">
          <div className="stat-card">
            <div className="stat-label">Start Date</div>
            <div className="stat-value">{formatDate(pool.tournament_fixture?.start_date)}</div>
          </div>
          <div className="stat-card">
            <div className="stat-label">Teams</div>
            <div className="stat-value">{pool.number_of_teams || 0}</div>
          </div>
          <div className="stat-card">
            <div className="stat-label">Players Per Team</div>
            <div className="stat-value">{pool.max_players || 0}</div>
          </div>
          <div className="stat-card">
            <div className="stat-label">Players Scored</div>
            <div className="stat-value">{pool.num_player_used_for_scoring || 0}</div>
          </div>
          <div className="stat-card">
            <div className="stat-label">Cut Rule</div>
            <div className="stat-value">{pool.all_players_must_make_cut ? "All Must Make Cut" : "No Cut Rule"}</div>
          </div>
          <div className="stat-card">
            <div className="stat-label">Tie Breaker</div>
            <div className="stat-value">{pool.tie_breaker === "Total Score" ? "Winning Score" : pool.tie_breaker}</div>
          </div>
        </div>

        <div className="teams-preview">
          <div className="golf-pools-sub-title">
            <h3>Teams</h3>
          </div>
          {teams.length > 0 ? (
            <div className="golf-pools-table-wrapper">
              <table className="golf-pools-table">
                <thead>
                  <tr>
                    <th>Owner</th>
                    <th className="text-center">Position</th>
                    <th className="text-center">Paid</th>
                    <th className="text-center">Players</th>
                    <th className="text-center">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {teams.map((team) => (
                    <tr 
                      key={team.id}
                      onClick={() => {
                        const tournamentStarted = new Date(pool.tournament_fixture?.start_date) <= new Date();
                        const isOwner = team.owner_details?.id === loggedInUser;
                        if (!tournamentStarted && !isOwner) {
                          alert("Team details will be visible when the tournament begins.");
                          return;
                        }
                        navigate(`/pool/${poolId}/team/${team.id}`);
                      }}
                      className="clickable"
                    >
                      <td className="truncate">{team.owner_details?.full_name}</td>
                      <td className="text-center">
                        {team.rank || '--'}
                      </td>
                      <td className="text-center">
                        <div className="status-badge-container">
                          <span className={`golf-pools-badge ${team.isActive ? 'status-in-progress' : 'status-error'} on-grey`}>
                            {team.isActive ? 'Yes' : 'No'}
                          </span>
                        </div>
                      </td>
                      <td className="text-center">
                        <div className="status-badge-container">
                          <span className={`golf-pools-badge ${team.line_items[0]?.count === pool.max_players ? 'status-in-progress' : 'status-error'} on-grey`}>
                            {team.line_items[0]?.count || 0}/{pool.max_players || '-'}
                          </span>
                        </div>
                      </td>
                      <td className="text-center">
                        <div className="status-badge-container">
                          <span className={`golf-pools-badge ${team.isActive ? 'status-in-progress' : 'status-error'} on-grey`}>
                            {team.isActive ? 'Active' : 'Inactive'}
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="golf-pools-empty-container">No teams have joined this pool yet.</div>
          )}
        </div>
      </div>

      {showModal && (
        <PoolDetailsModal
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          poolData={pool}
          mode="edit"
          loggedInUser={loggedInUser}
          refreshData={fetchPoolData}
          onTeamStatusChange={(updatedTeams) => setTeams(updatedTeams)}
        />
      )}

      {showInviteModal && (
        <Modal 
          isOpen={showInviteModal} 
          onClose={() => setShowInviteModal(false)}
          isSubmitting={isSubmitting}
        >
          <InviteToPool 
            poolId={poolId} 
            userId={loggedInUser} 
          />
        </Modal>
      )}

      {showManageModal && (
        <Modal 
          isOpen={showManageModal} 
          onClose={() => setShowManageModal(false)}
          isSubmitting={isSubmitting}
        >
          <ManagePoolEntries 
            poolId={poolId}
            canEdit={canEditRecord(pool.tournament_fixture?.start_date)}
            onTeamStatusChange={(updatedTeams) => setTeams(updatedTeams)}
          />
        </Modal>
      )}
    </div>
  );
};

export default PoolPage; 