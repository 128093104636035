import { useState, useEffect } from "react";
import { supabase } from "../ReusableComponents/supabaseClient";
import { toast } from "react-toastify";

export const selectedPoolDataStructure = {
  id: null,
  tournamentStatus: "",
  cutValue: "",
  currentRound: "",
};


export const fetchPoolDetailsById = async (poolId) => {
    try {
        const { data, error } = await supabase
            .from('pool')
            .select("id, name, tournament_status, round_status, cut_value, current_round")
            .eq('id', poolId)
            .single();  // Ensures that only one record is returned

        if (error) throw error;
        return data;
    } catch (error) {
        toast.error(`Error fetching pool details: ${error.message}`);
        throw error;  // Re-throwing the error to be handled by the calling component
    }
};



export function useFetchPoolLeaderboardOptions(selectedPoolId) {
  const [poolOptions, setPoolOptions] = useState([]);
  const [poolLoading, setLoading] = useState(true);

  useEffect(() => {
    if (!Number(localStorage.getItem("userId"))) {
      setLoading(false);
      return;
    }

    const fetchData = async () => {
      const { data, error } = await supabase
        .from("team")
        .select(
          "pool(id, name, current_round, cut_value, tournament_status, round_status, tournament_fixture(id, start_date)), owner(id)"
        )
        .eq("owner", Number(localStorage.getItem("userId")));

      if (data) {
        data.sort((a, b) => {
          const dateA = new Date(a.pool.tournament_fixture.start_date),
            dateB = new Date(b.pool.tournament_fixture.start_date);
          if (dateA - dateB === 0) {
            return a.pool.id - b.pool.id;
          }
          return dateA - dateB;
        });
        setPoolOptions(data);
      }
      if (error) {
        toast.error(`Error: ${error}`);
        setLoading(false);
      } else {
        setPoolOptions(data);
        setLoading(false);
      }
    };

    fetchData();

    const poolTableSubscription = supabase
      .channel("poolTableSubscription")
      .on(
        "postgres_changes",
        { event: "UPDATE", schema: "public", table: "pool", filter: `id=eq.${selectedPoolId}` },
        (payload) => {
          fetchData();
        }
      )
      .subscribe();

    return () => supabase.removeChannel(poolTableSubscription);
  }, [selectedPoolId]);

  return { poolOptions, poolLoading };
}

export function useFetchTeamByPool(poolId) {
  const [teams, setTeams] = useState([]);
  const [teamLoading, setLoading] = useState(false);

  useEffect(() => {
    if (!poolId) {
      setTeams([]);
      return;
    }

    setLoading(true);

    const fetchData = async () => {
      try {
        const { data, error } = await supabase
          .from("team")
          .select(
            "id, paid, missed_cut, rank, team_world_ranking_average, team_score_to_par, team_score_total, owner(id, full_name), pool(id,tournament_fixture(start_date))"
          )
          .eq("pool", poolId)
          .eq("active", true)
          .order("rank", { ascending: true });

        if (error) {
          toast.error(`Error: ${error}`);
        }

        if (data) {
          data.sort(sortTeams);
        }


        setTeams(data);
      } catch (error) {
        toast.error(`Error: ${error}`);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

      const teamTableSubscription = supabase
      .channel("teamTableSubscription")
      .on(
        "postgres_changes",
        { event: "UPDATE", schema: "public", table: "team", filter: `pool=eq.${poolId}` },
        (payload) => {
          fetchData();
        }
      )
      .subscribe();

    return () => supabase.removeChannel(teamTableSubscription);
  }, [poolId]);

  return { teams, teamLoading };
}

export function useFetchTeamLineItemsByPool(poolId) {
  const [teamLineItems, setTeamLineItems] = useState([]);
  const [teamLinesLoading, setLoading] = useState(false);

  useEffect(() => {
    if (!poolId) {
      setTeamLineItems([]);
      return;
    }

    setLoading(true);

    const fetchData = async () => {
      try {
        const { data, error } = await supabase
          .from("teamLineItem")
          .select(
            "id, team, player_world_ranking, tee_time, holes_played, players_tournament_status, total_score, total_score_to_par, round_1_to_par, round_2_to_par, round_3_to_par, round_4_to_par, player(id, player_name), pool(num_player_used_for_scoring, tournament_status)"
          )
          .eq("pool", poolId);

        if (error) {
          toast.error(`Error: ${error}`);
        }

        if (data) {
          data.sort(sortTeamLineItems);
        }

        setTeamLineItems(data);
      } catch (error) {
        toast.error(`Error: ${error}`);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

      const teamLineItemTableSubscription = supabase
      .channel("teamLineItemTableSubscription")
      .on(
        "postgres_changes",
        { event: "UPDATE", schema: "public", table: "teamLineItem", filter: `pool=eq.${poolId}` },
        (payload) => {
          fetchData();
        }
      )
      .subscribe();

    return () => supabase.removeChannel(teamLineItemTableSubscription);
  }, [poolId]);

  return { teamLineItems, teamLinesLoading };
}


const sortTeams = (a, b) => {
  // Special handling for "MC" rank and "--" scoreTotal, including "E" as 0
  const isMCA = a.rank.toUpperCase() === "MC";
  const isMCB = b.rank.toUpperCase() === "MC";
  
  const scoreA = a.team_score_to_par === "--" ? Infinity :
                 a.team_score_to_par.toUpperCase() === "E" ? 0 :
                 parseInt(a.team_score_to_par, 10) || Infinity;
  
  const scoreB = b.team_score_to_par === "--" ? Infinity :
                 b.team_score_to_par.toUpperCase() === "E" ? 0 :
                 parseInt(b.team_score_to_par, 10) || Infinity;

  // Sort teams with scoreTotal of "--" to the bottom
  if (scoreA === Infinity && scoreB !== Infinity) return 1;
  if (scoreA !== Infinity && scoreB === Infinity) return -1;

  // Next, handle the "MC" ranks
  if (isMCA && !isMCB) return 1;
  if (!isMCA && isMCB) return -1;

  // If both have "MC" ranks, then sort by score
  if (isMCA && isMCB) {
    return scoreA - scoreB;
  }

  // Function to extract numeric rank, treating "T" prefixes as ties (e.g., "T1" becomes 1)
  const getNumericRank = (rank) => parseInt(rank.replace('T', ''), 10);
  const rankA = getNumericRank(a.rank);
  const rankB = getNumericRank(b.rank);

  // Finally, sort by rank
  // If ranks are equal, then sort by score as a secondary criteria
  const rankDifference = rankA - rankB;
  if (rankDifference !== 0) {
    return rankDifference;
  }

  // Sort by score if ranks are equal
  return scoreA - scoreB;
};



const sortTeamLineItems = (a, b) => {
  const scoreToNumeric = (score) => {
    if (score === "E" || score === "-" || score === "--") return 0; 
    return parseInt(score, 10); 
  };

  const totalScoreToNumeric = (score) => {
    if (score === "0" || score === "-" || score === "--") return Number.MAX_SAFE_INTEGER; // Push "0", "-", "--" to the bottom
    return parseInt(score, 10);
  };

  const isInactiveStatus = (status) => {
    return ["cut", "wd", "dq"].includes(status.toLowerCase());
  };

  const aScore = scoreToNumeric(a.total_score_to_par);
  const bScore = scoreToNumeric(b.total_score_to_par);

  const aTotalScore = totalScoreToNumeric(a.total_score);
  const bTotalScore = totalScoreToNumeric(b.total_score);

  const aInactive = isInactiveStatus(a.players_tournament_status);
  const bInactive = isInactiveStatus(b.players_tournament_status);

  // Check if both players are inactive or active, then sort based on total score to par
  if (aInactive === bInactive) {
    if (aScore !== bScore) {
      return aScore - bScore; // Sort by score to par value
    }

    // If score to par values are equal, sort by total score
    return aTotalScore - bTotalScore;
  }

  // If only one player is inactive, sort to put inactive players lower
  return aInactive ? 1 : -1;
};
