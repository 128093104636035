import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { IoChevronBackOutline } from "react-icons/io5";
import { TfiSave } from "react-icons/tfi";
import { PacmanLoader } from "react-spinners";
// import PlayerSelect from "./PlayerSelectDropdown";
import { toast } from "react-toastify";
import { supabase } from "../ReusableComponents/supabaseClient";
import moment from "moment-timezone";

const getScoreClass = (score) => {
  if (score === "E" || score === "--") return "even";
  const numScore = parseInt(score);
  if (isNaN(numScore)) return "even";
  return numScore < 0 ? "under" : "over";
};

const isPlayerSelected = (playerId, selectedPlayers, currentIndex) => {
  return selectedPlayers.some((player, index) => 
    index !== currentIndex && player.id?.toString() === playerId?.toString()
  );
};

const TeamViewForm = React.memo(({ 
  records = [],
  isEditable: initialEditable = false,
  poolData,
  setSpinner,
  setFormError,
  setRefetchTrigger
}) => {
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const [canShowEditButton, setCanShowEditButton] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  
  const validRecords = useMemo(() => 
    Array.isArray(records) ? records : []
  , [records]);
  
  const [selectedPlayers, setSelectedPlayers] = useState(() => {
    if (validRecords.length > 0 && validRecords[0]?.player) {
      return validRecords.map(record => ({
        id: record.player?.id || '',
        name: record.player?.player_name || '',
        worldRanking: record.player?.world_ranking_position || 0
      }));
    }
    return validRecords.map(() => ({
      id: '',
      name: '',
      worldRanking: 0
    }));
  });

  const [tieBreakerScore, setTieBreakerScore] = useState(
    validRecords[0]?.team?.tie_breaker_score || ''
  );
  
  // Add state for player fixtures
  const [playerFixtures, setPlayerFixtures] = useState([]);

  // Add effect to handle initial loading
  useEffect(() => {
    if (validRecords.length > 0 || !poolData) {
      setIsLoading(false);
    }
  }, [validRecords.length, poolData]);

  // Check if user can edit (team owner and before tournament start)
  useEffect(() => {
    const checkEditPermissions = async () => {
      if (!poolData || !validRecords[0]?.team) {
        setCanShowEditButton(false);
        setIsEditing(false);
        return;
      }

      // Check ownership
      const userId = localStorage.getItem('userId');
      const isOwner = String(validRecords[0].team.owner.id) === String(userId);
      if (!isOwner) {
        setCanShowEditButton(false);
        setIsEditing(false);
        return;
      }

      // Get tournament timing info
      const { data: poolDataWithTime } = await supabase
        .from('pool')
        .select(`
          first_tee_time,
          time_zone,
          tournament_fixture (start_date)
        `)
        .eq('id', validRecords[0].pool.id)
        .single();

      if (!poolDataWithTime) {
        setCanShowEditButton(false);
        setIsEditing(false);
        return;
      }

      // Get tournament start time
      const now = moment().tz(poolDataWithTime.time_zone);
      let tournamentStart;

      // Get tournament start date in the pool's timezone
      const startDate = moment.tz(poolDataWithTime.tournament_fixture.start_date, 'UTC')
        .tz(poolDataWithTime.time_zone);

      if (poolDataWithTime.first_tee_time) {
        // Convert first tee time to pool timezone
        tournamentStart = moment.tz(poolDataWithTime.first_tee_time, 'UTC')
          .tz(poolDataWithTime.time_zone);

        // If first tee time is before tournament start date, use start date
        if (tournamentStart.isBefore(startDate)) {
          tournamentStart = startDate;
        }
      } else {
        tournamentStart = startDate;
      }

      // Can edit if now is before tournament start or has late team permission
      const tournamentNotStarted = now.isBefore(tournamentStart);
      const canEdit = tournamentNotStarted || validRecords[0].team.owner.can_create_late_team;

      setCanShowEditButton(initialEditable && canEdit);
      
      // Force exit edit mode if can't edit anymore
      if (!canEdit && isEditing) {
        setIsEditing(false);
        toast.info("Tournament has started - editing is no longer available");
      }
    };

    // Check immediately
    checkEditPermissions();

    // Then check every minute
    const interval = setInterval(checkEditPermissions, 60000);

    // Cleanup interval on unmount
    return () => clearInterval(interval);
  }, [poolData, validRecords, initialEditable, isEditing]);

  useEffect(() => {
    const fetchPlayerFixtures = async () => {
      if (!poolData?.tournament_fixture?.id) return;
      
      try {
        const { data, error } = await supabase
          .from('playersTournaments')
          .select(`
            id,
            player:playersFixture (
              id,
              player_name,
              world_ranking_position
            )
          `)
          .eq('tournament', poolData.tournament_fixture.id)
          .order('player->world_ranking_position', { ascending: true });

        if (error) throw error;

        // Format data for dropdown
        const fixtures = data.map(item => ({
          key: item.id,
          value: `${item.player.player_name} (${item.player.world_ranking_position})`,
          playerId: item.player.id,
          playerName: item.player.player_name,
          worldRanking: item.player.world_ranking_position
        }));

        setPlayerFixtures(fixtures);
      } catch (error) {
        toast.error('Error loading players');
      }
    };

    fetchPlayerFixtures();
  }, [poolData?.tournament_fixture?.id]);

  // Add handler for player selection
  const handlePlayerSelect = (index, event) => {
    const option = event.target.options[event.target.selectedIndex];
    if (!option) return;

    const playerId = option.getAttribute('data-player-id');
    const playerName = option.getAttribute('data-player-name');
    const worldRanking = option.getAttribute('data-world-ranking');

    // Only update if we have all the required values
    if (playerId && playerName && worldRanking) {
      const newPlayer = {
        id: playerId,
        name: playerName,
        worldRanking: parseInt(worldRanking) || 0
      };
      
      const newPlayers = [...selectedPlayers];
      newPlayers[index] = newPlayer;
      setSelectedPlayers(newPlayers);
    }
  };

  if (isLoading) {
    return (
      <div className="loading-container">
        <PacmanLoader className="spinner" color="#1a472a" loading={true} size={25} aria-label="Loading Spinner" />
      </div>
    );
  }

  if (!validRecords.length || !validRecords[0]?.pool) {
    return (
      <div className="team-page">
        <div className="team-page-header">
          <div className="team-page-header-row">
            <button onClick={() => navigate(-1)} className="back-link">
              <IoChevronBackOutline /> Back
            </button>
            <div style={{ width: '80px' }}></div>
          </div>
          <p className="message">No pool data available.</p>
        </div>
      </div>
    );
  }

  const showTieBreakerRow = validRecords[0].pool.tie_breaker === "Winning Score";
  const poolName = validRecords[0].pool.name || "Pool";
  const ownerName = validRecords[0].team?.owner?.full_name || "Unknown";

  const handleSubmit = async (e) => {
    if (!isEditing) return;
    e.preventDefault();
    
    try {
      setSpinner(true);
      setFormError("");

      // Validate all players are selected
      if (selectedPlayers.some(player => !player?.id)) {
        throw new Error("Please select all players");
      }

      // Validate tie breaker score if required
      if (showTieBreakerRow && !tieBreakerScore) {
        throw new Error("Please enter a tie breaker score");
      }

      // Update team record with new tie breaker score if needed
      if (showTieBreakerRow) {
        const { error: teamUpdateError } = await supabase
          .from("team")
          .update({ tie_breaker_score: tieBreakerScore })
          .eq("id", validRecords[0].team.id)
          .select();

        if (teamUpdateError) {
          throw teamUpdateError;
        }
      }

      // Update team line items with new players
      for (let i = 0; i < validRecords.length; i++) {
        const lineItem = validRecords[i];
        const selectedPlayer = selectedPlayers[i];

        // First verify the line item exists
        const { data: checkData, error: checkError } = await supabase
          .from("teamLineItem")
          .select("*")
          .eq("id", lineItem.id)
          .single();

        if (checkError || !checkData) {
          throw new Error(`Line item ${lineItem.id} not found. Please refresh and try again.`);
        }

        // If line item exists, proceed with update
        const { data: lineItemData, error: lineItemUpdateError } = await supabase
          .from("teamLineItem")
          .update({ 
            player: Number(selectedPlayer.id),
            player_world_ranking: selectedPlayer.worldRanking
          })
          .eq("id", lineItem.id)
          .select()
          .single();

        if (lineItemUpdateError) {
          throw new Error(`Failed to update player ${i + 1}. Please try again.`);
        }

        if (!lineItemData) {
          throw new Error(`Failed to verify update for player ${i + 1}. Please try again.`);
        }
      }

      // After successful save, exit edit mode
      setIsEditing(false);
      
      // Trigger refetch of data
      setRefetchTrigger(prev => prev + 1);
      
      toast.success("Team updated successfully!");
    } catch (error) {
      setFormError(error.message || "An error occurred while updating the team");
      toast.error(error.message || "An error occurred while updating the team");
    } finally {
      setSpinner(false);
    }
  };

  return (
    <div className="page-container">
      <div className="page-header">
        <div className="team-page-header-row">
          <button onClick={() => navigate(-1)} className="back-link">
            <IoChevronBackOutline /> Back
          </button>
          <h1 className="page-title">{poolName}</h1>
          {canShowEditButton && (
            <button 
              onClick={() => setIsEditing(!isEditing)} 
              className="edit-button"
            >
              {isEditing ? 'Cancel' : 'Edit'}
            </button>
          )}
        </div>
      </div>

      <div className="team-title-container">
        <h2 className="page-team-title">Team: {ownerName}</h2>
      </div>

      <div className="data-container">
        <div className="data-item">
          <span className="data-key">Tournament Status:</span>
          <span className="data-value">{validRecords[0].pool.tournament_status || "--"}</span>
        </div>
        <div className="data-item">
          <span className="data-key">Round Status:</span>
          <span className="data-value">{validRecords[0].pool.round_status || "--"}</span>
        </div>
        <div className="data-item">
          <span className="data-key">Current Round:</span>
          <span className="data-value">{validRecords[0].pool.current_round || "--"}</span>
        </div>
        <div className="data-item">
          <span className="data-key">Cut Line:</span>
          <span className="data-value">{validRecords[0].pool.cut_value || "--"}</span>
        </div>
      </div>

      <form onSubmit={handleSubmit} className="team-form">
        <div className="team-players-section">
          <div className="team-table-wrapper">
            <div className="team-table-container">
              <table className="team-table">
                <thead>
                  <tr>
                    <th>Position</th>
                    <th>Player</th>
                    <th>World Ranking</th>
                    <th>To Par</th>
                  </tr>
                </thead>
                <tbody>
                  {validRecords.map((record, index) => (
                    <tr key={record.id}>
                      <td>{index + 1}</td>
                      <td>
                        {isEditing ? (
                          <select 
                            className="player-select"
                            onChange={(e) => handlePlayerSelect(index, e)}
                            value={selectedPlayers[index]?.id || ""}
                          >
                            <option value="" hidden>Select a Player</option>
                            {playerFixtures.map((fixture) => (
                              <option
                                key={fixture.key}
                                value={fixture.playerId}
                                data-player-id={fixture.playerId}
                                data-player-name={fixture.playerName}
                                data-world-ranking={fixture.worldRanking}
                                disabled={isPlayerSelected(fixture.playerId, selectedPlayers, index)}
                              >
                                {fixture.value}
                              </option>
                            ))}
                          </select>
                        ) : (
                          record.player?.player_name || "Unknown"
                        )}
                      </td>
                      <td>
                        <span className="world-rank-badge">
                          {record.player_world_ranking || "--"}
                        </span>
                      </td>
                      <td className="score-cell">
                        <span className={`score-badge ${getScoreClass(record.total_score_to_par)}`}>
                          {record.total_score_to_par || "--"}
                        </span>
                      </td>
                    </tr>
                  ))}
                  {showTieBreakerRow && (
                    <tr className="tie-breaker-row">
                      <td colSpan="3" className="tie-breaker-label">Tie Breaker Score:</td>
                      <td>
                        {isEditing ? (
                          <input
                            type="number"
                            min="0"
                            value={tieBreakerScore}
                            onChange={(e) => setTieBreakerScore(e.target.value)}
                            className="tie-breaker-input"
                          />
                        ) : (
                          validRecords[0].team?.tie_breaker_score || "--"
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {isEditing && (
          <div className="button-container">
            <button type="submit" className="submit-button">
              Save Team
              <TfiSave className="save-icon" />
            </button>
          </div>
        )}
      </form>
    </div>
  );
});

export default TeamViewForm; 