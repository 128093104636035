import React, { useState } from "react";
import "./HomeBackgroundWrapper.css";
import backgroundImage from "../images/loginBackground.png";
import Modal from "../ReusableComponents/Modal";
import ContactUsForm from "../ReusableComponents/ContactUsForm";

const HomeBackgroundWrapper = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Make openModal available globally for the help bar
  if (typeof window !== 'undefined') {
    window.openContactModal = () => setIsModalOpen(true);
  }

  const closeModal = () => setIsModalOpen(false);
  const handleSubmissionStatusChange = (status) => {
    setIsSubmitting(status);
  };

  return (
    <div className="background-wrapper" style={{ backgroundImage: `url(${backgroundImage})` }}>
      {children}

      {isModalOpen && (
        <Modal isOpen={isModalOpen} onClose={closeModal} isSubmitting={isSubmitting}>
          <ContactUsForm closeModal={closeModal} onSubmissionStatusChange={handleSubmissionStatusChange} />
        </Modal>
      )}
    </div>
  );
};

export default HomeBackgroundWrapper;
