import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useFetchPoolAndLineItemData } from "./TeamHelper";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import { PacmanLoader } from "react-spinners";
import { IoChevronBackOutline } from "react-icons/io5";
import TeamViewForm from "./TeamViewForm";
import "../styles/golfPoolStyles.css";
import { supabase } from "../Utilities/supabaseClient";
import moment from "moment-timezone";

const Team = () => {
  const { poolId, teamId } = useParams();
  const navigate = useNavigate();
  const [refetchTrigger, setRefetchTrigger] = useState(0);
  const [content, setContent] = useState(null);
  const [initialLoading, setInitialLoading] = useState(true);
  
  const { 
    poolData, 
    teamLineItem, 
    hasLineItems, 
    loading, 
    error, 
    isOwner,
    teamStatus 
  } = useFetchPoolAndLineItemData(
    poolId ? Number(poolId) : null,
    refetchTrigger,
    teamId ? Number(teamId) : null
  );

  console.log("Debug Team.js:", {
    poolId,
    teamId,
    hasLineItems,
    teamLineItemLength: teamLineItem?.length,
    isOwner,
    teamStatus,
    error
  });

  useEffect(() => {
    if (!loading && poolData) {
      setInitialLoading(false);
    }
  }, [loading, poolData]);

  const [spinner, setSpinner] = useState(false);
  const [formError, setFormError] = useState("");
  const showError = error || formError;
  const isLoading = loading || spinner || initialLoading;

  const hasTournamentStarted = (pool) => {
    if (!pool?.first_tee_time || !pool?.time_zone) return false;
    
    const firstTeeTime = moment.tz(pool.first_tee_time, pool.time_zone);
    const now = moment().tz(pool.time_zone);
    
    return now.isAfter(firstTeeTime);
  };

  const renderContent = async () => {
    if (!poolData) {
      return (
        <div className="golf-pools-card">
          <div className="golf-pools-container">
            <div className="golf-pools-header">
              <div className="golf-pools-header-row">
                <button onClick={() => navigate(-1)} className="golf-pools-back-link">
                  <IoChevronBackOutline /> Back
                </button>
                <div style={{ width: '80px' }}></div>
              </div>
              <p className="golf-pools-empty-container">No pool data available.</p>
            </div>
          </div>
        </div>
      );
    }

    const tournamentStarted = hasTournamentStarted(poolData);

    try {
      // Check if tournament field is set
      const { data: playerTournaments, error: playerTournamentsError } = await supabase
        .from("playersTournaments")
        .select("id")
        .eq("tournament", poolData.tournament_fixture.id)
        .limit(1);

      if (playerTournamentsError) {
        toast.error(`Error fetching tournament data: ${playerTournamentsError.message}`);
        throw playerTournamentsError;
      }

      if (!playerTournaments || playerTournaments.length === 0) {
        return (
          <div className="golf-pools-card">
            <div className="golf-pools-container">
              <div className="golf-pools-header">
                <div className="golf-pools-header-row">
                  <button onClick={() => navigate(-1)} className="golf-pools-back-link">
                    <IoChevronBackOutline /> Back
                  </button>
                  <div style={{ width: '80px' }}></div>
                </div>
                <p className="golf-pools-empty-container">⛳ The tournament field has not been set yet. Please check back on Monday of tournament week when the official field is announced. 🏌️</p>
              </div>
            </div>
          </div>
        );
      }

      // If tournament has started, show team view form for everyone
      if (hasLineItems && teamLineItem && teamLineItem.length > 0) {
        return (
          <div className="details-card">
            <TeamViewForm 
              records={teamLineItem} 
              isEditable={!tournamentStarted && isOwner}
              poolData={poolData}
              setSpinner={setSpinner}
              setFormError={setFormError}
              setRefetchTrigger={setRefetchTrigger}
            />
          </div>
        );
      }

      return (
        <div className="golf-pools-card">
          <div className="golf-pools-container">
            <div className="golf-pools-header">
              <div className="golf-pools-header-row">
                <button onClick={() => navigate(-1)} className="golf-pools-back-link">
                  <IoChevronBackOutline /> Back
                </button>
                <div style={{ width: '80px' }}></div>
              </div>
              <p className="golf-pools-empty-container">No team data available for this tournament.</p>
            </div>
          </div>
        </div>
      );

    } catch (error) {
      toast.error(`Error checking tournament field: ${error.message}`);
      return (
        <div className="golf-pools-card">
          <div className="golf-pools-container">
            <div className="golf-pools-header">
              <div className="golf-pools-header-row">
                <button onClick={() => navigate(-1)} className="golf-pools-back-link">
                  <IoChevronBackOutline /> Back
                </button>
                <div style={{ width: '80px' }}></div>
              </div>
              <p className="golf-pools-empty-container">Error loading tournament data. Please try again later.</p>
            </div>
          </div>
        </div>
      );
    }
  };

  useEffect(() => {
    const loadContent = async () => {
      if (!isLoading && !showError) {
        const renderedContent = await renderContent();
        setContent(renderedContent);
      }
    };
    loadContent();
  }, [poolData, teamLineItem, hasLineItems, isOwner, teamStatus, isLoading, showError]);

  if (isLoading) {
    return (
      <div className="golf-pools-card">
        <div className="golf-pools-container">
          <div className="golf-pools-loading-container">
            <PacmanLoader className="spinner" color="#1a472a" loading={true} size={25} aria-label="Loading Spinner" />
          </div>
          <div className="golf-pools-header">
            <div className="golf-pools-header-row">
              <button onClick={() => navigate(-1)} className="golf-pools-back-link">
                <IoChevronBackOutline /> Back
              </button>
              <div style={{ width: '80px' }}></div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (showError) {
    return (
      <div className="golf-pools-card">
        <div className="golf-pools-header">
          <div className="golf-pools-header-row">
            <button onClick={() => navigate(-1)} className="golf-pools-back-link">
              <IoChevronBackOutline /> Back
            </button>
            <div style={{ width: '80px' }}></div>
          </div>
          <p className="golf-pools-empty-container error-message">{error?.message || formError || error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="golf-pools-card">
      <ToastContainer 
        position="top-center" 
        autoClose={5000} 
        hideProgressBar={false} 
        newestOnTop={false} 
        closeOnClick 
        rtl={false} 
        pauseOnFocusLoss 
        draggable 
        pauseOnHover 
        theme="colored" 
      />
      <div className="golf-pools-container">
        {content}
      </div>
    </div>
  );
};

export default Team;
