import React, { useEffect, useState, useCallback  } from "react";
import { PacmanLoader } from "react-spinners";
import { fetchInviteLinksByUserId, fetchPoolModalData } from "./PoolModalHelper";
import { supabase } from "../ReusableComponents/supabaseClient";
import PoolDetailsModal from "./PoolDetailsModal";
import "../styles/golfPoolStyles.css";
import moment from "moment";
import "moment-timezone";
import { useNavigate } from "react-router-dom";
import { IoChevronBackOutline } from "react-icons/io5";

// const getStatusClass = (status) => {
//   switch (status?.toLowerCase()) {
//     case 'not started':
//       return 'status-not-started';
//     case 'in progress':
//       return 'status-in-progress';
//     case 'suspended':
//       return 'status-suspended';
//     case 'official':
//       return 'status-official';
//     default:
//       return 'status-not-started';
//   }
// };

const AllPools = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [selectedPool, setSelectedPool] = useState(null);
  const [formMode, setFormMode] = useState("insert");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [userId, setUserId] = useState(localStorage.getItem("userId"));

  const getStatusClass = (status) => {
    switch (status?.toLowerCase()) {
      case 'not started':
        return 'status-not-started';
      case 'in progress':
        return 'status-in-progress';
      case 'suspended':
        return 'status-suspended';
      case 'official':
        return 'status-official';
      default:
        return 'status-not-started';
    }
  };

  useEffect(() => {
    const handleStorageChange = () => {
      setUserId(localStorage.getItem("userId"));
    };

    // Add event listener for local storage changes
    window.addEventListener('storage', handleStorageChange);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const refreshData = useCallback(async () => {
    if (!userId) return; 
    setLoading(true);
    try {
      const fetchedData = await fetchInviteLinksByUserId(userId);
      setData(fetchedData);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setLoading(false);
    }
  }, [userId]);

  useEffect(() => {
  
    refreshData();

    const inviteLinkSubscription = supabase
      .channel("inviteLinkChanges")
      .on("postgres_changes", { event: "INSERT", schema: "public", table: "InviteLink", filter: `person=eq.${userId}` }, (payload) => {
        refreshData(); // Opt to append to state or refetch as needed
      })
      .on("postgres_changes", { event: "UPDATE", schema: "public", table: "InviteLink", filter: `person=eq.${userId}` }, (payload) => {
        refreshData(); // Opt to update state or refetch as needed
      })
      .subscribe();

    // Subscribe to changes in the pool table
    const poolSubscription = supabase
      .channel('poolChanges')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'pool'
        },
        () => {
          refreshData();
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(inviteLinkSubscription);
      poolSubscription.unsubscribe();
    };
  }, [userId, refreshData]);

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const openModalWithData = async (poolId) => {
    setLoading(true);
    try {
      const poolDetails = await fetchPoolModalData(poolId);
      if (poolDetails && poolDetails.length > 0) {
        setSelectedPool(poolDetails[0]); 
        setFormMode("edit"); 
        setModalIsOpen(true);
      }
    } catch (error) {
      console.error("Error fetching pool details: ", error);
    } finally {
      setLoading(false);
    }
  };
  
  const handleRowClick = (poolId) => {
    navigate(`/pool/${poolId}`);
  };

  if (loading) {
    return (
      <div className="golf-pools-loading-container">
        <PacmanLoader className="spinner" color="#1a472a" loading={true} size={25} aria-label="Loading Spinner" />
      </div>
    );
  }

  return (
    <div className="golf-pools-card">
      <div className="golf-pools-container">
        <div className="golf-pools-header">
          <div className="golf-pools-header-row">
            <button onClick={() => navigate(-1)} className="golf-pools-back-link">
              <IoChevronBackOutline /> Back
            </button>
            <h1 className="golf-pools-title">My Pools</h1>
            <div style={{ width: '80px' }}></div>
          </div>
        </div>

        {data.length > 0 ? (
          <div className="golf-pools-table-wrapper">
            <table className="golf-pools-table">
              <thead>
                <tr>
                  <th className="text-center">Pool #</th>
                  <th className="text-center">Tournament</th>
                  <th className="text-center">Date</th>
                  <th className="text-center">Status</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item) => (
                  <tr key={item.id} className="clickable" onClick={() => handleRowClick(item.pool?.id)}>
                    <td >{item.pool?.id}</td>
                    <td className="alignLeft">{item.pool?.tournament_fixture?.name}</td>
                    <td >{moment(item.pool?.tournament_fixture?.start_date).format("MM/DD/YY")}</td>
                    <td>
                      <div className="status-badge-container">
                        <span className={`golf-pools-badge ${getStatusClass(item.pool?.tournament_status)}`}>
                          {item.pool?.tournament_status || 'Not Started'}
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="golf-pools-empty-container">
            <p>No pools found</p>
          </div>
        )}
      </div>

      {modalIsOpen && (
        <PoolDetailsModal
          isOpen={modalIsOpen}
          onClose={closeModal}
          poolData={selectedPool}
          mode={formMode}
          refreshData={refreshData}
          loggedInUser={userId}
          onTeamStatusChange={(updatedTeams) => {
            refreshData();
          }}
        />
      )}
    </div>
  );
};

export default AllPools; 