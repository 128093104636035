import React from "react";
import { FaClock, FaGolfBall } from "react-icons/fa";
const moment = require("moment-timezone");

function TeamLineItemsTable({ lineItems, teamId }) {
  const filteredLineItems = lineItems.filter((item) => item.team === teamId);

  const getScoreClass = (score) => {
    if (!score || score === "--" || score === "-") return "";
    if (score === "E") return "score-even";
    const numScore = parseInt(score);
    if (isNaN(numScore)) return "";
    return numScore < 0 ? "score-under-par" : numScore > 0 ? "score-over-par" : "score-even";
  };

  const formatScore = (score) => {
    if (!score || score === "--" || score === "-") return "--";
    if (score === "E") return "E";
    const numScore = parseInt(score);
    if (isNaN(numScore)) return score;
    return numScore > 0 ? `+${numScore}` : numScore;
  };

  const getPlayerStatus = (item, index) => {
    if (item.players_tournament_status === "CUT") return "missed-cut";
    if (item.players_tournament_status === "WD") return "withdrawn";
    if (index >= item.pool.num_player_used_for_scoring && item.pool.tournament_status !== "Not Started") {
      return "not-counting";
    }
    return "";
  };

  // Sort line items by total score and world ranking
  const sortedLineItems = React.useMemo(() => {
    return [...filteredLineItems].sort((a, b) => {
      // First compare by total score to par
      const scoreA = a.total_score_to_par === "E" ? 0 : parseInt(a.total_score_to_par) || 0;
      const scoreB = b.total_score_to_par === "E" ? 0 : parseInt(b.total_score_to_par) || 0;
      
      if (scoreA !== scoreB) {
        return scoreA - scoreB;
      }
      
      // If scores are equal, sort by world ranking (lower rank is better)
      const rankA = parseInt(a.player_world_ranking) || 999;
      const rankB = parseInt(b.player_world_ranking) || 999;
      return rankA - rankB;
    });
  }, [filteredLineItems]);

  return (
    <div className="player-details-table">
      <table className="line-items-table">
        <thead>
          <tr>
            <th>Player</th>
            <th>Rank</th>
            <th>R1</th>
            <th>R2</th>
            <th>R3</th>
            <th>R4</th>
            <th>Status</th>
            <th>Total</th>
            <th>To Par</th>
          </tr>
        </thead>
        <tbody>
          {sortedLineItems.map((item, index) => {
            const playerStatus = getPlayerStatus(item, index);
            const holesPlayed = ["--", "-"].includes(item.holes_played) && item.tee_time != null 
              ? <span className="tee-time"><FaClock className="clock-icon" />{moment(item.tee_time).format("h:mm A")}</span>
              : item.holes_played;

            return (
              <tr key={item.id} className={playerStatus}>
                <td className="player-cell">
                  <span className="player-name">{item.player.player_name}</span>
                  {item.players_tournament_status === "CUT" && 
                    <FaGolfBall className="status-icon cut-icon" title="Missed Cut" />
                  }
                </td>
                <td className="rank-cell">{item.player_world_ranking}</td>
                <td className={`score-cell ${getScoreClass(item.round_1_to_par)}`}>
                  {formatScore(item.round_1_to_par)}
                </td>
                <td className={`score-cell ${getScoreClass(item.round_2_to_par)}`}>
                  {formatScore(item.round_2_to_par)}
                </td>
                <td className={`score-cell ${getScoreClass(item.round_3_to_par)}`}>
                  {formatScore(item.round_3_to_par)}
                </td>
                <td className={`score-cell ${getScoreClass(item.round_4_to_par)}`}>
                  {formatScore(item.round_4_to_par)}
                </td>
                <td className="status-cell">
                  {holesPlayed}
                </td>
                <td className="total-cell">
                  {item.total_score}
                </td>
                <td className={`score-cell ${getScoreClass(item.total_score_to_par)}`}>
                  {formatScore(item.total_score_to_par)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default React.memo(TeamLineItemsTable);
