import React, { createContext, useContext, useState, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

const AuthContext = createContext(null);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("userId") !== null
  );

  useEffect(() => {
    // Update isLoggedIn when localStorage changes
    const handleStorageChange = () => {
      setIsLoggedIn(localStorage.getItem("userId") !== null);
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const navigate = useNavigate();
  
  const login = (userId, userName, email) => {
    localStorage.setItem("userId", userId); 
    localStorage.setItem("userName", userName); 
    localStorage.setItem("email", email); 
    setIsLoggedIn(true);
    navigate("/home");
  };

  const logout = () => {
    localStorage.removeItem("userId"); 
    localStorage.removeItem("userName"); 
    localStorage.removeItem("email"); 
    setIsLoggedIn(false);
    navigate("/");
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const ProtectedRoute = ({ children }) => {
  const { isLoggedIn } = useAuth();
  const location = useLocation();

  if (!isLoggedIn) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};
