import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoChevronBackOutline } from "react-icons/io5";
import { supabase } from '../ReusableComponents/supabaseClient';
import { PacmanLoader } from "react-spinners";
import moment from 'moment';
import '../styles/golfPoolStyles.css';

const AllTeamsView = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [teams, setTeams] = useState([]);
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    const fetchTeams = async () => {
      try {
        setLoading(true);
        const { data, error } = await supabase
          .from('team')
          .select(`
            id,
            active,
            paid,
            rank,
            number_of_line_items,
            pool (
              id,
              name,
              max_players,
              tournament_fixture (
                start_date
              )
            )
          `)
          .eq('owner', userId)
          .order('created_date', { ascending: false });

        if (error) throw error;
        setTeams(data || []);
      } catch (error) {
        console.error('Error fetching teams:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTeams();
  }, [userId]);

  const handleRowClick = (poolId, teamId) => {
    navigate(`/pool/${poolId}/team/${teamId}`);
  };

  if (loading) {
    return (
      <div className="golf-pools-loading-container">
        <PacmanLoader className="spinner" color="#1a472a" loading={true} size={25} aria-label="Loading Spinner" />
      </div>
    );
  }

  return (
    <div className="golf-pools-card">
      <div className="golf-pools-container">
        <div className="golf-pools-header">
          <div className="golf-pools-header-row">
            <button onClick={() => navigate(-1)} className="golf-pools-back-link">
              <IoChevronBackOutline /> Back
            </button>
            <h1 className="golf-pools-title">My Teams</h1>
            <div style={{ width: '80px' }}></div>
          </div>
        </div>

        {teams.length > 0 ? (
          <div className="golf-pools-table-wrapper">
            <table className="golf-pools-table">
              <thead>
                <tr>
                  <th className="text-center">Pool</th>
                  <th className="text-center">Date</th>
                  <th className="text-center">Position</th>
                  <th className="text-center">Players</th>
                  <th className="text-center">Paid</th>
                </tr>
              </thead>
              <tbody>
                {teams.map((team) => (
                  <tr 
                    key={team.id}
                    onClick={() => handleRowClick(team.pool.id, team.id)}
                    className="clickable"
                  >
                    <td className="alignLeft">{team.pool.name}</td>
                    <td>
                      {team.pool.tournament_fixture?.start_date 
                        ? moment(team.pool.tournament_fixture.start_date).format("MM/DD/YY")
                        : 'N/A'}
                    </td>
                    <td>
                      <span className="golf-pools-badge">
                        {team.rank || '--'}
                      </span>
                    </td>
                    <td>
                      <div className="status-badge-container">
                        <span className={`golf-pools-badge ${team.number_of_line_items === team.pool.max_players ? 'status-in-progress' : 'status-suspended'}`}>
                          {team.number_of_line_items || 0}/{team.pool.max_players || '-'}
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className="status-badge-container">
                        <span className={`golf-pools-badge ${team.paid ? 'status-in-progress' : 'status-suspended'}`}>
                          {team.paid ? 'Yes' : 'No'}
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="golf-pools-empty-container">
            <p>No teams available</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default AllTeamsView; 