import { supabase } from "../ReusableComponents/supabaseClient";
import moment from "moment";
import "moment-timezone";

export const fetchInviteLinksByUserId = async (userId) => {
  try {
    const { data, error } = await supabase
      .from('InviteLink')
      .select(`
        id,
        pool (
          id,
          name,
          tournament_status,
          current_round,
          round_status,
          tournament_fixture (
            name,
            start_date
          )
        )
      `)
      .eq('person', userId)
      .order('created_date', { ascending: false });

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error fetching invite links:', error);
    throw error;
  }
};

export const fetchPoolModalData = async (poolId) => {
  try {
    const { data, error } = await supabase
      .from("pool")
      .select(
        `
        id,
        all_players_must_make_cut,
        num_player_used_for_scoring,
        max_players,
        tie_breaker,
        tournament_fixture:tournamentsFixture!pool_tournament_fixture_fkey(
          id,
          name,
          start_date,
          tournament_id,
          season
        ),
        number_of_teams,
        name,
        owner
      `
      )
      .eq("id", poolId)
      .single(); 

    if (error) {
      throw error;
    }

    return data;
  } catch (error) {
    return null;
  }
};

export const updatePoolDetails = async (poolData) => {
  const { data: tournament, error: tournamentError } = await supabase
    .from("tournamentsFixture")
    .select("id, name, start_date")
    .eq("id", poolData.tournament)
    .single();

  if (tournamentError) {
    return { error: tournamentError };
  }

  const { data, error } = await supabase
    .from("pool")
    .update({
      name: tournament.name,
      all_players_must_make_cut: poolData.allMakeCut,
      num_player_used_for_scoring: poolData.numPlayersScored,
      max_players: poolData.teamSize,
      tie_breaker: poolData.tieBreaker,
      tournament_fixture: tournament.id,
    })
    .eq("id", poolData.poolId);

  if (error) {
    throw error;
  }

  return data;
};

export async function fetchTournamentOptions(currentTournamentId = null) {
  try {
    const currentDate = moment().subtract(1, 'day').startOf('day').toISOString();
    
    let query = supabase
      .from("tournamentsFixture")
      .select(`
        id,
        name,
        start_date,
        tournament_id,
        season
      `)
      .order("start_date", { ascending: true });

    if (currentTournamentId) {
      query = query.or(`tournament_id.eq.${currentTournamentId},and(start_date.gte.${currentDate})`);
    } else {
      query = query.gte("start_date", currentDate);
    }

    const { data, error } = await query;

    if (error) {
      throw error;
    }

    return data || [];
  } catch (error) {
    return [];
  }
}

export function validateFormData(formData) {
  console.log('Validating form data:', formData);
  const errors = {};

  if (!formData.tournament) {
    errors.tournament = "Tournament is required";
  }

  if (!formData.tieBreaker) {
    errors.tieBreaker = "Tie Breaker is required";
  }

  const teamSize = parseInt(formData.teamSize, 10);
  const numPlayersScored = parseInt(formData.numPlayersScored, 10);

  if (isNaN(teamSize) || teamSize < 1 || teamSize > 15) {
    errors.teamSize = "Team Size must be between 1 and 15";
  }

  if (isNaN(numPlayersScored) || numPlayersScored < 1 || numPlayersScored > 15) {
    errors.numPlayersScored = "# of Players must be between 1 and 15";
  }

  if (!isNaN(teamSize) && !isNaN(numPlayersScored) && numPlayersScored > teamSize) {
    errors.numPlayersScored = "Must be less or equal to Max Players";
  }

  return errors;
}

async function createPool(formData, userId, tourneyId, formattedDate, currentDate) {
  try {
    
    const { data: tournament, error: tournamentError } = await supabase
      .from("tournamentsFixture")
      .select("id, name, start_date, tournament_id")
      .eq("id", tourneyId)
      .single();

    if (tournamentError) {
      console.error("Error fetching tournament:", tournamentError);
      return { error: tournamentError };
    }

    if (!tournament) {
      console.error("Tournament not found");
      return { error: "Tournament not found" };
    }

    const poolName = tournament.name;

    const { data: pool, error: poolError } = await supabase
      .from("pool")
      .insert([
        {
          name: poolName,
          all_players_must_make_cut: formData.allMakeCut,
          num_player_used_for_scoring: parseInt(formData.numPlayersScored, 10),
          max_players: parseInt(formData.teamSize, 10),
          tie_breaker: formData.tieBreaker,
          tournament_fixture: tournament.id,
          owner: userId,
          created_date: currentDate,
          modified_date: currentDate,
          tour_fixtures: 10
        },
      ])
      .select();

    if (poolError) {
      console.error("Error creating pool:", poolError);
      return { error: poolError };
    }

    return { pool: pool[0], poolName: poolName };
  } catch (error) {
    console.error("Error creating pool:", error);
    return { error: error.message };
  }
}

async function createInviteLink(poolId, userId, currentDate) {
  try {
    const { data: inviteLink, error: inviteLinkError } = await supabase
      .from("InviteLink")
      .insert([
        {
          pool: poolId,
          person: userId,
          user: true,
          created_date: currentDate,
          modified_date: currentDate,
        },
      ])
      .select();

    if (inviteLinkError) {
      console.error("Error creating invite link:", inviteLinkError);
      return { error: inviteLinkError };
    }

    return { inviteLink: inviteLink };
  } catch (error) {
    console.error("Error creating invite link:", error);
    return { error: error.message };
  }
}

export async function savePoolAndInvite(formData) {
  try {
    const userId = localStorage.getItem("userId");
    const currentDate = moment().tz("America/Chicago").utc().format();
    const formattedDate = moment(formData.start_date).format("MMM Do, YYYY");

    const { pool, poolName, error: poolError } = await createPool(formData, userId, formData.tournament, formattedDate, currentDate);

    if (poolError) {
      return { error: poolError };
    }

    // Create a team record for the pool owner
    const { data: team, error: teamError } = await supabase
      .from("team")
      .insert([
        {
          owner: userId,
          pool: pool.id,
          created_date: currentDate,
          modified_date: currentDate
        }
      ])
      .select();

    if (teamError) {
      console.error("Error creating team:", teamError);
      return { error: teamError };
    }

    const { inviteLink, error: inviteLinkError } = await createInviteLink(pool.id, userId, currentDate);

    if (inviteLinkError) {
      return { error: inviteLinkError };
    }

    return {
      success: true,
      pool: pool,
      poolName: poolName,
      inviteLink: inviteLink
    };
  } catch (error) {
    console.error("Error in savePoolAndInvite:", error);
    return { error: error.message };
  }
}

export async function fetchInviteLinksByPool(poolId) {
  try {
    const { data, error } = await supabase
      .from("InviteLink")
      .select(`
        id,
        user,
        email,
        created_date,
        modified_date,
        paid,
        person:person( id, email, full_name),
        team_created,
        pool:pool(id, tournamentsFixture:tournamentsFixture(name, start_date, end_date))
      `)
      .eq("pool", poolId);

    if (error) {
      throw error;
    }

    return data;
  } catch (error) {
    return [];
  }
}

export async function updateInviteLinkPaidStatus(linkId, paidStatus) {
  try {
    const { data, error } = await supabase
      .from("InviteLink")
      .update({ paid: paidStatus })
      .match({ id: linkId });

    if (error) {
      throw error;
    }

    return data;
  } catch (error) {
    return null;
  }
}

export const resendInviteEmail = async (email, poolId) => {
  try {
    const response = await fetch('/api/email/invitePlayerEmail', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, poolId, isResend: true }),
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const canEditRecord = (startDate, numberOfTeams, maxTeams) => {
  if (!startDate) return true;
  
  const tournamentStarted = moment(startDate).isBefore(moment());
  if (tournamentStarted) return false;
  
  if (numberOfTeams && maxTeams && numberOfTeams >= maxTeams) return false;
  
  return true;
};