// LoginForm.js
import React from "react";

import "./Login.css";

function LoginForm({
  formState,
  formErrors,
  handleInputChange,
  handleSubmit,
  handleTabClick,
  handleForgotPassword,
}) {

  const renderFormFields = () => {
    if (formState.forgotPassword) {
      return (
        <>
          <h2 className="form-title">Reset Password</h2>
          <div className="form-group">
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formState.email}
              onChange={handleInputChange}
              className="inputStyle"
              required
            />
            {formErrors.email && (
              <div className="error-message">{formErrors.email}</div>
            )}
          </div>

          <button name="resetPassword" type="submit" className="submitButton">
            Send Reset Link
          </button>

          <button
            type="button"
            className="link-button"
            onClick={() => handleTabClick("login")}
          >
            Back to Login
          </button>
        </>
      );
    } else if (formState.isLogin) {
      return (
        <>
          <h2 className="form-title">Welcome Back</h2>
          <div className="form-group">
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formState.email}
              onChange={handleInputChange}
              className="inputStyle"
              required
            />
            {formErrors.email && (
              <div className="error-message">{formErrors.email}</div>
            )}
          </div>

          <div className="form-group">
            <input
              type="password"
              name="password"
              placeholder="Password"
              value={formState.password}
              onChange={handleInputChange}
              className="inputStyle"
              required
            />
            {formErrors.password && (
              <div className="error-message">{formErrors.password}</div>
            )}
          </div>

          <button name="login" type="submit" className="submitButton">
            Sign In
          </button>

          <button
            type="button"
            className="link-button"
            onClick={handleForgotPassword}
          >
            Forgot Password?
          </button>
        </>
      );
    } else {
      return (
        <>
          <h2 className="form-title">Create Account</h2>
          <div className="form-group">
            <input
              type="text"
              name="firstName"
              placeholder="First Name"
              value={formState.firstName}
              onChange={handleInputChange}
              className="inputStyle"
              required
            />
          </div>

          <div className="form-group">
            <input
              type="text"
              name="lastName"
              placeholder="Last Name"
              value={formState.lastName}
              onChange={handleInputChange}
              className="inputStyle"
              required
            />
          </div>

          <div className="form-group">
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formState.email}
              onChange={handleInputChange}
              className="inputStyle"
              required
            />
            {formErrors.email && (
              <div className="error-message">{formErrors.email}</div>
            )}
          </div>

          <div className="form-group">
            <input
              type="password"
              name="password"
              placeholder="Password"
              value={formState.password}
              onChange={handleInputChange}
              className="inputStyle"
              required
            />
            {formErrors.password && (
              <div className="error-message">{formErrors.password}</div>
            )}
          </div>

          <button name="register" type="submit" className="submitButton">
            Create Account
          </button>
        </>
      );
    }
  };

  return (
    <form className="loginForm" onSubmit={handleSubmit}>
      {!formState.forgotPassword && (
        <div className="tab">
          <div
            className={`tab-item ${formState.isLogin ? "active" : ""}`}
            onClick={() => handleTabClick("login")}
          >
            Sign In
          </div>
          <div
            className={`tab-item ${!formState.isLogin ? "active" : ""}`}
            onClick={() => handleTabClick("register")}
          >
            Sign Up
          </div>
        </div>
      )}
      {renderFormFields()}
    </form>
  );
}

export default LoginForm;
