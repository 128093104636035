import React, { useEffect, useState, useCallback  } from "react";
import { PacmanLoader } from "react-spinners";
import { fetchInviteLinksByUserId, fetchPoolModalData } from "./PoolModalHelper";
import { supabase } from "../ReusableComponents/supabaseClient";
import PoolDetailsModal from "./PoolDetailsModal";
import "./Pool.css";
// import './PoolLink.css';
import moment from "moment";
import "moment-timezone";
import { useNavigate } from "react-router-dom";
import { IoChevronBackOutline } from "react-icons/io5";

const AllPools = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [selectedPool, setSelectedPool] = useState(null);
  const [formMode, setFormMode] = useState("insert");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [userId, setUserId] = useState(localStorage.getItem("userId"));

  const getStatusClass = (status) => {
    switch (status) {
      case 'Not Started':
        return 'status-not-started';
      case 'In Progress':
        return 'status-in-progress';
      case 'Suspended':
        return 'status-suspended';
      case 'Official':
      case 'Complete':
        return 'status-official';
      default:
        return 'status-not-started';
    }
  };

  useEffect(() => {
    const handleStorageChange = () => {
      setUserId(localStorage.getItem("userId"));
    };

    // Add event listener for local storage changes
    window.addEventListener('storage', handleStorageChange);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const refreshData = useCallback(async () => {
    if (!userId) return; 
    setLoading(true);
    try {
      const fetchedData = await fetchInviteLinksByUserId(userId);
      setData(fetchedData);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setLoading(false);
    }
  }, [userId]);

  useEffect(() => {
  
    refreshData();

    const inviteLinkSubscription = supabase
      .channel("inviteLinkChanges")
      .on("postgres_changes", { event: "INSERT", schema: "public", table: "InviteLink", filter: `person=eq.${userId}` }, (payload) => {
        refreshData(); // Opt to append to state or refetch as needed
      })
      .on("postgres_changes", { event: "UPDATE", schema: "public", table: "InviteLink", filter: `person=eq.${userId}` }, (payload) => {
        refreshData(); // Opt to update state or refetch as needed
      })
      .subscribe();

    // Subscribe to changes in the pool table
    const poolSubscription = supabase
      .channel('poolChanges')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'pool'
        },
        () => {
          refreshData();
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(inviteLinkSubscription);
      poolSubscription.unsubscribe();
    };
  }, [userId, refreshData]);

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const openModalWithData = async (poolId) => {
    setLoading(true);
    try {
      const poolDetails = await fetchPoolModalData(poolId);
      if (poolDetails && poolDetails.length > 0) {
        setSelectedPool(poolDetails[0]); 
        setFormMode("edit"); 
        setModalIsOpen(true);
      }
    } catch (error) {
      console.error("Error fetching pool details: ", error);
    } finally {
      setLoading(false);
    }
  };
  
  const handleRowClick = (poolId) => {
    navigate(`/pool/${poolId}`);
  };

  return (
    <div className="pool-link-table-card">
      {loading && (
        <div className="loading-pool-overlay">
          <PacmanLoader className="spinner" color="#1a472a" loading={true} size={25} aria-label="Loading Spinner" />
        </div>
      )}

      <div className="pool-link-table-container">
        <div className="pool-header">
          <div className="pool-header-row">
            <button onClick={() => navigate(-1)} className="back-link">
              <IoChevronBackOutline /> Back
            </button>
            <h1 className="pool-title">My Pools</h1>
            <div style={{ width: '80px' }}></div>
          </div>
        </div>

        <PoolDetailsModal 
          isOpen={modalIsOpen} 
          onClose={closeModal} 
          poolData={selectedPool} 
          mode={formMode} 
          refreshData={refreshData} 
          loggedInUser={userId}
          onTeamStatusChange={(updatedTeams) => {
            refreshData();
          }}
        />

        {!loading && data.length === 0 && (
          <div className="no-pool-link-data-container">
            <p> No pools available </p>
          </div>
        )}

        {!loading && data.length > 0 && (
          <table className="pool-link-table">
            <thead>
              <tr>
                <th className="pool-link-th pool-number">Pool #</th>
                <th className="pool-link-th tournament">Tournament</th>
                <th className="pool-link-th start-date">Start Date</th>
                <th className="pool-link-th text-center">Round</th>
                <th className="pool-link-th text-center">Round Status</th>
                <th className="pool-link-th status">Status</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item) => (
                <tr 
                  key={item.id} 
                  onClick={() => handleRowClick(item.pool?.id)}
                  className="clickable-row"
                >
                  <td className="pool-link-td">{item.pool?.id}</td>
                  <td className="pool-link-td">{item.pool?.tournament_fixture?.name}</td>
                  <td className="pool-link-td">
                    {moment(item.pool?.tournament_fixture?.start_date).format("MMM Do, YYYY")}
                  </td>
                  <td className="pool-link-td text-center">
                    {item.pool?.current_round || '-'}
                  </td>
                  <td className="pool-link-td text-center">
                    <div className="status-badge-container">
                      <span className={`status-badge ${getStatusClass(item.pool?.round_status)}`}>
                        {item.pool?.round_status || 'Not Started'}
                      </span>
                    </div>
                  </td>
                  <td className="pool-link-td text-center">
                    <div className="status-badge-container">
                      <span className={`status-badge ${getStatusClass(item.pool?.tournament_status)}`}>
                        {item.pool?.tournament_status || 'Not Started'}
                      </span>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default AllPools; 