import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useSupabaseUserAuth from '../Utilities/useSupabaseUserAuth';
import { toast } from 'react-toastify';
import BackgroundWrapper from './HomeBackgroundWrapper';
import './Login.css';

function ResetPassword() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { completePasswordReset } = useSupabaseUserAuth();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: ''
  });
  const [formErrors, setFormErrors] = useState({
    password: '',
    confirmPassword: ''
  });

  const token = searchParams.get('token');
  const email = searchParams.get('email');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    // Clear error when user starts typing
    setFormErrors(prev => ({
      ...prev,
      [name]: ''
    }));
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.password) {
      errors.password = 'Password is required';
    } else if (formData.password.length < 6) {
      errors.password = 'Password must be at least 6 characters';
    }

    if (!formData.confirmPassword) {
      errors.confirmPassword = 'Please confirm your password';
    } else if (formData.password !== formData.confirmPassword) {
      errors.confirmPassword = 'Passwords do not match';
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    if (!token || !email) {
      toast.error('Invalid reset link');
      navigate('/');
      return;
    }

    setIsSubmitting(true);
    try {
      await completePasswordReset(token, email, formData.password);
      toast.success('Password has been reset successfully');
      navigate('/', { replace: true });
    } catch (error) {
      console.error('Error resetting password:', error);
      toast.error(error.message || 'Failed to reset password');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="main-container">
      <BackgroundWrapper>
        <div className="auth-container">
          <div className="auth-form-container">
            <h2 className="form-title">Reset Your Password</h2>
            <form onSubmit={handleSubmit} className="loginForm">
              <div className="form-group">
                <input
                  type="password"
                  name="password"
                  placeholder="New Password"
                  value={formData.password}
                  onChange={handleInputChange}
                  className="inputStyle"
                  disabled={isSubmitting}
                  required
                />
                {formErrors.password && (
                  <div className="error-message">{formErrors.password}</div>
                )}
              </div>

              <div className="form-group">
                <input
                  type="password"
                  name="confirmPassword"
                  placeholder="Confirm New Password"
                  value={formData.confirmPassword}
                  onChange={handleInputChange}
                  className="inputStyle"
                  disabled={isSubmitting}
                  required
                />
                {formErrors.confirmPassword && (
                  <div className="error-message">{formErrors.confirmPassword}</div>
                )}
              </div>

              <button
                type="submit"
                className="submitButton"
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Resetting Password...' : 'Reset Password'}
              </button>

              <button
                type="button"
                className="link-button"
                onClick={() => navigate('/')}
                disabled={isSubmitting}
              >
                Back to Login
              </button>
            </form>
          </div>
        </div>
      </BackgroundWrapper>
    </div>
  );
}

export default ResetPassword; 