// hooks/supabaseUserAuth.js
import { useCallback } from "react";
import { supabase } from "../ReusableComponents/supabaseClient";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment-timezone";
import bcrypt from "bcryptjs";

const useSupabaseUserAuth = () => {
  const hashPassword = async (password) => {
    const salt = await bcrypt.genSalt(10);
    return bcrypt.hash(password, salt);
  };

  // Temporary function to fix password hash
  const fixPasswordHash = useCallback(async (email) => {
    try {
      const hashedPassword = await hashPassword('Pass1234');
      const date = moment().tz("America/Chicago").utc().format();

      const { error } = await supabase
        .from("user")
        .update({
          password: hashedPassword,
          modified_date: date,
        })
        .eq('email', email.toLowerCase());

      if (error) {
        console.error("Update error:", error);
        throw new Error(error.message || "Failed to update password hash");
      }

      return true;
    } catch (error) {
      console.error("Error fixing password hash:", error);
      throw error;
    }
  }, [hashPassword]);

  const createUser = useCallback(async (formState) => {
    try {
      const hashedPassword = await hashPassword(formState.password);
      const date = moment().tz("America/Chicago").utc().format();

      const createUserResponse = await supabase.from("user").insert([
        {
          first_name: formState.firstName,
          last_name: formState.lastName,
          email: formState.email.toLowerCase(),
          password: hashedPassword,
          created_date: date,
          modified_date: date,
          full_name: `${formState.firstName} ${formState.lastName}`
        },
      ]).select();

      if (createUserResponse.error) {
        const errorMessage = createUserResponse.error.message || "Error creating user";
        toast.error(errorMessage);
        throw new Error(errorMessage);
      }

      // Send welcome email
      try {
        const emailResponse = await fetch("/api/email/newUserEmail", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: `${formState.firstName} ${formState.lastName}`,
            email: formState.email,
          }),
        });

        if (!emailResponse.ok) {
          console.error("Error sending welcome email:", await emailResponse.text());
        }
      } catch (error) {
        console.error("Error sending welcome email:", error);
        // Don't throw here as the user is already created
      }

      return createUserResponse.data;
    } catch (error) {
      const errorMessage = error.message || "Error creating user";
      toast.error(errorMessage);
      throw error;
    }
  }, []);

  const initiatePasswordReset = useCallback(async (email) => {
    try {
      // Generate a reset token and expiry
      const resetToken = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
      const resetExpiry = moment().tz("America/Chicago").add(1, 'hour').utc().format();
      const date = moment().tz("America/Chicago").utc().format();

      // Update user with reset token and expiry
      const updateResponse = await supabase
        .from("user")
        .update({
          reset_token: resetToken,
          reset_token_expires: resetExpiry,
          modified_date: date,
        })
        .match({ email: email.toLowerCase() })
        .select();

      if (updateResponse.error) {
        throw new Error(updateResponse.error.message);
      }

      if (!updateResponse.data || updateResponse.data.length === 0) {
        throw new Error("No account found with this email");
      }

      // Use APP_URL from environment if available, otherwise use window.location.origin
      const baseUrl = process.env.REACT_APP_URL || window.location.origin;
      const resetLink = `${baseUrl}/reset-password?token=${resetToken}&email=${encodeURIComponent(email)}`;
      
      const emailResponse = await fetch("/api/email/resetPasswordEmail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          resetLink
        }),
      });

      if (!emailResponse.ok) {
        throw new Error("Failed to send reset email");
      }

      toast.success("Password reset instructions have been sent to your email");
      return true;
    } catch (error) {
      console.error("Error initiating password reset:", error);
      toast.error(error.message || "Failed to initiate password reset");
      throw error;
    }
  }, []);

  const completePasswordReset = useCallback(async (token, email, newPassword) => {
    try {
      // Verify token and expiry
      const { data: userData, error: fetchError } = await supabase
        .from("user")
        .select("*")
        .match({ 
          email: email.toLowerCase(),
          reset_token: token 
        })
        .single();

      if (fetchError || !userData) {
        throw new Error("Invalid or expired reset token");
      }

      const tokenExpiry = moment(userData.reset_token_expires);
      if (moment().isAfter(tokenExpiry)) {
        throw new Error("Reset token has expired");
      }

      // Hash new password and update user
      const hashedPassword = await hashPassword(newPassword);
      const date = moment().tz("America/Chicago").utc().format();

      const { error: updateError } = await supabase
        .from("user")
        .update({
          password: hashedPassword,
          reset_token: null,
          reset_token_expires: null,
          modified_date: date,
        })
        .eq('email', email.toLowerCase());

      if (updateError) {
        console.error("Update error:", updateError);
        throw new Error(updateError.message || "Failed to update password");
      }

      // Send confirmation email
      try {
        const emailResponse = await fetch("/api/email/resetPasswordEmail", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email,
            resetLink: null // Indicates this is a confirmation email
          }),
        });

        if (!emailResponse.ok) {
          console.error("Error sending confirmation email:", await emailResponse.text());
        }
      } catch (error) {
        console.error("Error sending confirmation email:", error);
        // Don't throw here as the password is already reset
      }

      toast.success("Password has been successfully reset");
      return true;
    } catch (error) {
      console.error("Error completing password reset:", error);
      toast.error(error.message || "Failed to reset password");
      throw error;
    }
  }, [hashPassword]);

  const verifyPassword = useCallback(async (password, hashedPassword) => {
    try {
      return await bcrypt.compare(password, hashedPassword);
    } catch (error) {
      console.error("Error verifying password:", error);
      return false;
    }
  }, []);

  return { createUser, initiatePasswordReset, completePasswordReset, verifyPassword, fixPasswordHash };
};

export default useSupabaseUserAuth;
