import React, { useState } from "react";

function ContactForm({ closeModal, onSubmissionStatusChange }) {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const [isHovered, setIsHovered] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    onSubmissionStatusChange(true);
    try {
      const response = await fetch("/api/email/sendContactUsEmail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setFormData({
          name: "",
          email: "",
          subject: "",
          message: "",
        });
      }
      closeModal();
    } catch (error) {
      console.error("Error sending email:", error);
    } finally {
      onSubmissionStatusChange(false);
    }
  };

  return (
    <>
      <h2 className="form-title">Contact Us</h2>
      <form onSubmit={handleSubmit} className="loginForm">
        <div className="form-group">
          <input
            type="text"
            name="name"
            placeholder="Your Name"
            value={formData.name}
            className="inputStyle"
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <input
            type="email"
            name="email"
            placeholder="Your Email"
            value={formData.email}
            className="inputStyle"
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            name="subject"
            placeholder="Subject"
            value={formData.subject}
            className="inputStyle"
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <textarea
            name="message"
            placeholder="Your Message"
            value={formData.message}
            className="inputStyle"
            style={{ minHeight: "120px", resize: "vertical" }}
            onChange={handleChange}
            required
          />
        </div>
        <button
          type="submit"
          className="submitButton"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          Send Message
        </button>
      </form>
    </>
  );
}

export default ContactForm;
