import React from 'react';
// import useWindowSize from "../ReusableComponents/useWindowSize";

const BackgroundTheme = ({ children }) => {
  // const windowSize = useWindowSize();

  const backgroundStyle = {
    backgroundColor: '#121212', // Matching the Material UI dark theme background
    minHeight: '100vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  };

  return <div style={backgroundStyle}>{children}</div>;
};

export default BackgroundTheme;
