import React, { useState, useCallback, useEffect } from "react";
import { TfiSave } from "react-icons/tfi";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { 
  useGetPlayerTournamentFixtures, 
  useInsertTeamRecord, 
  useInsertTeamLineItems, 
  setTeam, fetchTeamId, 
  isOptionDisabled, 
  validateSelections, 
  setTeamLineItems
} from "./TeamHelper";
import PlayerSelectDropdown from './PlayerSelectDropdown';
import './Team.css';
import { supabase } from "../ReusableComponents/supabaseClient";

const TeamSelectForm = ({ poolData, setSpinner, setFormError, setRefetchTrigger }) => {
  console.log("TeamSelectForm rendering with poolData:", poolData);

  const [selectedPlayers, setSelectedPlayers] = useState({});
  const [tieBreakerScore, setTieBreakerScore] = useState("");
  const { insertTeamRecord } = useInsertTeamRecord();
  const { insertTeamLineItems } = useInsertTeamLineItems();

  // Log the tournament fixture data
  console.log("Tournament fixture data:", {
    directId: poolData.tournament_fixture,
    relationshipData: poolData.tournament_fixture,
  });

  const { playerFixtures, loading: playersLoading, error: playersError } = useGetPlayerTournamentFixtures(poolData);

  console.log("Player fixtures state:", {
    fixturesCount: playerFixtures?.length,
    loading: playersLoading,
    error: playersError
  });

  const handleSelectChange = useCallback((playerIndex, event) => {
    const selectedOption = event.target.options[event.target.selectedIndex];
    const playerData = {
      playerFixture: selectedOption.getAttribute("data-player-fixture"),
      playerId: selectedOption.getAttribute("data-player-id"),
      playerName: selectedOption.getAttribute("data-player-name"),
      worldRanking: selectedOption.getAttribute("data-world-ranking"),
    };

    setSelectedPlayers((prevOptions) => ({
      ...prevOptions,
      [playerIndex]: playerData,
    }));
  }, []);

  const handleTieBreakerChange = useCallback((event) => {
    setTieBreakerScore(event.target.value);
  }, []);

  useEffect(() => {
    if (playersError) {
      console.log("Players error:", playersError);
      setFormError(playersError);
    }
  }, [playersError, setFormError]);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      console.log("Form submission started with:", {
        selectedPlayers,
        tieBreakerScore,
      });

      const validationResult = validateSelections(selectedPlayers, poolData, tieBreakerScore);

      if (validationResult) {
        setFormError(validationResult);
        return;
      }

      try {
        setSpinner(true);
        setFormError(null);

        // First check if a team already exists for this user and pool
        const { data: existingTeam, error: teamError } = await supabase
          .from("team")
          .select("id")
          .eq("owner", Number(localStorage.getItem("userId")))
          .eq("pool", poolData.id)
          .single();

        if (teamError && teamError.code !== 'PGRST116') {
          throw new Error("Error checking for existing team: " + teamError.message);
        }

        let teamId;
        if (existingTeam) {
          // Use existing team
          teamId = existingTeam.id;
        } else {
          // Create new team if none exists
          const teamData = setTeam(poolData, tieBreakerScore);
          if (!teamData || Object.keys(teamData).length === 0) {
            throw new Error("No valid team data provided.");
          }
          teamId = await insertTeamRecord(teamData);
          if (!teamId) {
            throw new Error("Failed to create team record.");
          }
        }

        const teamLineItems = setTeamLineItems(selectedPlayers, poolData.id, teamId);

        if (!teamLineItems || teamLineItems.length === 0) {
          throw new Error("No valid team line items provided.");
        }

        await insertTeamLineItems(teamLineItems);
        setRefetchTrigger((prev) => prev + 1);

        if (typeof teamId === "number") {
          fetch("/api/email/teamSubmissionEmail", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              teamId: teamId,
            }),
          })
            .then((response) => response.text())
            .then((data) => {
              console.log("Email sent successfully:", data);
            })
            .catch((error) => {
              console.error("Error sending email:", error);
            });
        }
      } catch (error) {
        setFormError(error.message);
      } finally {
        setSpinner(false);
      }
    },
    [poolData, selectedPlayers, tieBreakerScore, setSpinner, setFormError, setRefetchTrigger, insertTeamRecord, insertTeamLineItems]
  );

  // Return early if poolData is not loaded yet
  if (!poolData || !poolData.tournament_fixture) {
    return null;
  }

  if (playersLoading) {
    return (
      <div className="loading-container">
        <AiOutlineLoading3Quarters className="loading-icon spin" />
      </div>
    );
  }

  if (playersError) {
    console.log("Players error:", playersError);
    return <div className="error-message">{playersError}</div>;
  }

  return (
    <div className="team-form-container">
      {playerFixtures && playerFixtures.length === 0 && (
        <p className="message">Fore! 🏌️ The tournament field has not been set. Please try again on the Monday the week of the tournament ⛳</p>
      )}
      {playerFixtures && playerFixtures.length > 0 && (
        <>
          <h2>{poolData.name}</h2>
          <form onSubmit={handleSubmit} className="team-form">
            {Array.from({ length: poolData.max_players }, (_, index) => (
              <PlayerSelectDropdown
                key={index}
                index={index}
                playerFixtures={playerFixtures}
                selectedPlayers={selectedPlayers}
                onPlayerSelect={handleSelectChange}
              />
            ))}
            {poolData.tie_breaker === "Winning Score" && (
              <div className="form-group">
                <label htmlFor="tieBreaker" className="form-label">Tie Breaker Score:</label>
                <div className="input-wrapper">
                  <input
                    type="number"
                    id="tieBreaker"
                    value={tieBreakerScore}
                    onChange={handleTieBreakerChange}
                    min="0"
                    className="form-input"
                  />
                  <div className="helper-text">
                    <span className="helper-text-label">Example:</span>
                    <span className="helper-text-value">276</span>
                    <span className="helper-text-note">(not -12)</span>
                  </div>
                </div>
              </div>
            )}
            <div className="button-container">
              <button type="submit" className="submit-button">
                Submit Team
                <TfiSave className="save-icon" />
              </button>
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default TeamSelectForm;
