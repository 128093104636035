import React, { useState, useEffect } from "react";
import TeamLineItemsTable from "./TeamLineItemsTable";
import { IoChevronBackCircle, IoChevronDownCircle } from "react-icons/io5";
import { TbGolfOff } from "react-icons/tb";
import { FaTrophy } from "react-icons/fa";
import moment from "moment";

function TeamTable({ teams, teamLineItems }) {
  const [expandedRows, setExpandedRows] = useState({});
  const [previousRanks, setPreviousRanks] = useState({});

  // Sort teams by rank first, then by world ranking for ties
  const sortedTeams = React.useMemo(() => {
    return [...teams].sort((a, b) => {
      if (a.rank === b.rank) {
        return parseFloat(a.team_world_ranking_average) - parseFloat(b.team_world_ranking_average);
      }
      if (a.rank === "MC") return 1;
      if (b.rank === "MC") return -1;
      return parseInt(a.rank) - parseInt(b.rank);
    });
  }, [teams]);

  useEffect(() => {
    const newRanks = {};
    sortedTeams.forEach(team => {
      newRanks[team.id] = team.rank;
    });
    setPreviousRanks(newRanks);
  }, [sortedTeams]);

  const toggleRow = (teamId) => {
    setExpandedRows((prev) => ({ ...prev, [teamId]: !prev[teamId] }));
  };

  const canSeeLineItems = (dateString, ownerId) => {
    if (!dateString) return false;

    const startDate = moment(dateString).tz("America/Los_Angeles");
    const currentDate = moment().tz("America/Los_Angeles").startOf("day");
    const loggedInUser = localStorage.getItem("userId");

    const tournamentStarted = startDate.isSameOrBefore(currentDate);
    const isOwnerLoggedInUser = ownerId.toString() === loggedInUser;
    const isSpecialUser = ['4', '83'].includes(loggedInUser);

    return tournamentStarted || isOwnerLoggedInUser || isSpecialUser;
  };

  const getScoreClass = (score) => {
    if (score === "E" || score === "--") return "score-even";
    const numScore = parseInt(score);
    if (isNaN(numScore)) return "";
    return numScore < 0 ? "score-under-par" : numScore > 0 ? "score-over-par" : "score-even";
  };

  const getRankDisplay = (rank, index, tournamentStatus) => {
    if (rank === "MC") return <span className="missed-cut">MC</span>;
    if (tournamentStatus === "Official" && index < 3) {
      return (
        <div className="rank-with-icon">
          <FaTrophy className={`trophy-icon trophy-${index + 1}`} />
          <span>{rank}</span>
        </div>
      );
    }
    return rank;
  };

  return (
    <table className="team-table">
      <thead>
        <tr>
          <th>Rank</th>
          <th>Team</th>
          <th>World Rank</th>
          <th>Total</th>
          <th>To Par</th>
          <th>Details</th>
        </tr>
      </thead>
      <tbody>
        {sortedTeams.map((team, index) => {
          const hasPositionChanged = previousRanks[team.id] && previousRanks[team.id] !== team.rank;
          return (
            <React.Fragment key={team.id}>
              <tr className={`${expandedRows[team.id] ? "expanded-row" : ""} ${hasPositionChanged ? "position-changed" : ""}`}>
                <td className="rank-cell">
                  {getRankDisplay(team.rank, index, team.pool?.tournament_status)}
                </td>
                <td className="team-name-cell">
                  {team.owner.full_name}
                  {/* {team.missed_cut && <FaGolfBall className="missed-cut-icon" title="Missed Cut" />} */}
                </td>
                <td className="world-rank-cell">
                  {parseFloat(team.team_world_ranking_average).toFixed(2)}
                </td>
                <td className="score-cell">
                  {team.team_score_total}
                </td>
                <td className={`score-cell ${getScoreClass(team.team_score_to_par)}`}>
                  {team.team_score_to_par}
                </td>
                <td className="action-cell">
                  {canSeeLineItems(team.pool.tournament_fixture?.start_date, team.owner.id) ? (
                    <button 
                      onClick={() => toggleRow(team.id)} 
                      className={`expand-collapse-btn ${expandedRows[team.id] ? 'expanded' : ''}`}
                      aria-label={expandedRows[team.id] ? "Hide details" : "Show details"}
                    >
                      {expandedRows[team.id] ? <IoChevronDownCircle /> : <IoChevronBackCircle />}
                    </button>
                  ) : (
                    <TbGolfOff className="locked-icon" title="Team players will be visible when tournament begins" />
                  )}
                </td>
              </tr>
              {expandedRows[team.id] && (
                <tr className="details-row">
                  <td colSpan="6">
                    <div className="details-container">
                      <TeamLineItemsTable lineItems={teamLineItems} teamId={team.id} />
                    </div>
                  </td>
                </tr>
              )}
            </React.Fragment>
          );
        })}
      </tbody>
    </table>
  );
}

export default React.memo(TeamTable);
