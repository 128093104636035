import React from 'react';
import { isOptionDisabled } from './TeamHelper';

const PlayerSelectDropdown = React.memo(({ index, playerFixtures, selectedPlayers, onPlayerSelect }) => (
  <div className="form-group">
    <label htmlFor={`player-${index + 1}`} className="form-label">Player {index + 1}:</label>
    <select 
      id={`player-${index + 1}`} 
      onChange={(e) => onPlayerSelect(index, e)} 
      className="form-select"
    >
      <option value="" hidden>
        Select a Player
      </option>
      {playerFixtures.map((fixture) => (
        <option
          key={fixture.key}
          value={fixture.key}
          data-player-fixture={fixture.key}
          data-player-id={fixture.playerId}
          data-player-name={fixture.playerName}
          data-world-ranking={fixture.worldRanking}
          disabled={isOptionDisabled(fixture.key, selectedPlayers, index)}
          className={isOptionDisabled(fixture.key, selectedPlayers, index) ? 'option-disabled' : ''}
        >
          {fixture.value}
        </option>
      ))}
    </select>
  </div>
));

export default PlayerSelectDropdown; 