import React, { useEffect, useState } from "react";
import "./ManagePoolEntries.css";
import { fetchInviteLinksByPool, updateInviteLinkPaidStatus, resendInviteEmail } from "./PoolModalHelper";
import { MdOutlineMail } from "react-icons/md";
import { PacmanLoader } from "react-spinners";
import moment from "moment";
import "moment-timezone";
import { supabase } from '../ReusableComponents/supabaseClient';

const ManagePoolEntries = ({ poolId, canEdit, onTeamStatusChange }) => {
  const [inviteLinks, setInviteLinks] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadInviteLinks = async () => {
      setLoading(true);
      try {
        const data = await fetchInviteLinksByPool(poolId);
        setInviteLinks(data);
      } catch (error) {
        console.error("Failed to fetch invite links:", error);
      } finally {
        setLoading(false);
      }
    };

    loadInviteLinks();
  }, [poolId]);

  const handlePaidChange = async (id, paid) => {
    try {
      await updateInviteLinkPaidStatus(id, paid);
      
      // Update local state
      setInviteLinks(currentLinks => 
        currentLinks.map(link => 
          link.id === id ? { ...link, paid } : link
        )
      );

      // Fetch updated team data
      const { data: teamsData } = await supabase
        .from('team')
        .select(`
          *,
          owner_details:user!inner(
            full_name,
            id
          ),
          line_items:teamLineItem (count)
        `)
        .eq('pool', poolId)
        .order('team_world_ranking_average', { ascending: true })
        .limit(5);

      const { data: poolData } = await supabase
        .from('pool')
        .select('max_players')
        .eq('id', poolId)
        .single();

      const { data: inviteLinks } = await supabase
        .from('InviteLink')
        .select('*')
        .eq('pool', poolId);

      const teamsWithStatus = teamsData?.map(team => {
        const teamInviteLink = inviteLinks?.find(link => link.person === team.owner_details.id);
        const isActive = team.line_items[0].count === poolData.max_players && teamInviteLink?.paid;
        return {
          ...team,
          isActive
        };
      }) || [];

      // Update parent component
      onTeamStatusChange(teamsWithStatus);
    } catch (error) {
      console.error("Failed to update paid status:", error);
    }
  };

  const handleResendEmail = async (email, poolId) => {
    try {
      const response = await resendInviteEmail(email, poolId);
      if (response.ok) {
        alert("Email resent successfully!");
      } else {
        throw new Error("Failed to resend email");
      }
    } catch (error) {
      console.error(`Error: ${error.message}`);
    }
  };

  return (
    <div className="manage-entries-container">
      {loading && (
        <div className="loading-overlay">
          <PacmanLoader className="spinner" color="#1a472a" loading={true} size={25} aria-label="Loading Spinner" />
        </div>
      )}
      <div className="manage-entries-table-container">
        <table className="manage-entries-table">
          <thead>
            <tr>
              <th>Person</th>
              <th>Sent</th>
              <th>User</th>
              <th>Paid</th>
              <th>Resend</th>
            </tr>
          </thead>
          <tbody>
            {inviteLinks.length > 0 ? (
              inviteLinks.map((link) => (
                <tr key={link.id}>
                  <td>{link.person?.full_name || link.email}</td>
                  <td>{moment(link.invite_sent).format("MM/DD/YY")}</td>
                  <td>{link.user ? "Yes" : "No"}</td>
                  <td>
                    <input 
                      type="checkbox" 
                      className="manage-entries-checkbox" 
                      checked={link.paid} 
                      onChange={(e) => handlePaidChange(link.id, e.target.checked)}
                    />
                  </td>
                  <td>
                    <div className="resend-button-container">
                      <button 
                        className="manage-entries-button" 
                        onClick={() => handleResendEmail(link.email, link.pool?.id)} 
                        disabled={!canEdit}
                      >
                        <MdOutlineMail size={20} />
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5">No data available</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ManagePoolEntries;
