import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { IoChevronBackOutline } from "react-icons/io5";
import { TfiSave } from "react-icons/tfi";
import { PacmanLoader } from "react-spinners";
// import PlayerSelect from "./PlayerSelectDropdown";
import { toast } from "react-toastify";
import { supabase } from "../ReusableComponents/supabaseClient";
import moment from "moment-timezone";
import "../styles/golfPoolStyles.css";

const getScoreClass = (score) => {
  if (score === "E" || score === "--") return "score-even";
  const numScore = parseInt(score);
  if (isNaN(numScore)) return "score-even";
  return numScore < 0 ? "golf-pools-score-over" : "score-over";
};

const isPlayerSelected = (playerId, selectedPlayers, currentIndex) => {
  return selectedPlayers.some((player, index) => 
    index !== currentIndex && player.id === playerId
  );
};

const TeamViewForm = React.memo(({ 
  records = [],
  isEditable: initialEditable = false,
  poolData,
  setSpinner,
  setFormError,
  setRefetchTrigger
}) => {
  const navigate = useNavigate();
  const validRecords = useMemo(() => 
    Array.isArray(records) ? records : []
  , [records]);
  
  // Add state for player fixtures first
  const [playerFixtures, setPlayerFixtures] = useState([]);
  
  const [selectedPlayers, setSelectedPlayers] = useState(() => {
    const maxPlayers = poolData?.max_players || 0;
    const players = new Array(maxPlayers).fill(null).map((_, index) => {
      const record = validRecords[index];
      if (record?.player) {
        return {
          id: record.player.id,
          name: record.player.player_name,
          worldRanking: record.player.world_ranking_position,
          lineItemId: record.id
        };
      }
      return {
        id: '',
        name: '',
        worldRanking: 0,
        lineItemId: null
      };
    });
    return players;
  });

  const [tieBreakerScore, setTieBreakerScore] = useState(
    validRecords[0]?.team?.tie_breaker_score || ''
  );
  
  const [isEditing, setIsEditing] = useState(false);
  const [canShowEditButton, setCanShowEditButton] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Add effect to handle initial loading
  useEffect(() => {
    if (validRecords.length > 0 || !poolData) {
      setIsLoading(false);
    }
  }, [validRecords.length, poolData]);

  // Check if user can edit (team owner and before tournament start)
  useEffect(() => {
    const checkEditPermissions = async () => {
      if (!poolData || !validRecords[0]?.team) {
        setCanShowEditButton(false);
        setIsEditing(false);
        return;
      }

      // Check ownership
      const userId = localStorage.getItem('userId');
      const isOwner = String(validRecords[0].team.owner.id) === String(userId);
      if (!isOwner) {
        setCanShowEditButton(false);
        setIsEditing(false);
        return;
      }

      // Get tournament timing info
      const { data: poolDataWithTime } = await supabase
        .from('pool')
        .select(`
          first_tee_time,
          time_zone,
          tournament_fixture (start_date)
        `)
        .eq('id', validRecords[0].pool.id)
        .single();

      if (!poolDataWithTime) {
        setCanShowEditButton(false);
        setIsEditing(false);
        return;
      }

      // Get tournament start time
      const now = moment().tz(poolDataWithTime.time_zone);
      let tournamentStart;

      // Get tournament start date in the pool's timezone
      const startDate = moment.tz(poolDataWithTime.tournament_fixture.start_date, 'UTC')
        .tz(poolDataWithTime.time_zone);

      if (poolDataWithTime.first_tee_time) {
        // Convert first tee time to pool timezone
        tournamentStart = moment.tz(poolDataWithTime.first_tee_time, 'UTC')
          .tz(poolDataWithTime.time_zone);

        // If first tee time is before tournament start date, use start date
        if (tournamentStart.isBefore(startDate)) {
          tournamentStart = startDate;
        }
      } else {
        tournamentStart = startDate;
      }

      // Can edit if now is before tournament start or has late team permission
      const tournamentNotStarted = now.isBefore(tournamentStart);
      const canEdit = tournamentNotStarted || validRecords[0].team.owner.can_create_late_team;

      setCanShowEditButton(initialEditable && canEdit);
      
      // Force exit edit mode if can't edit anymore
      if (!canEdit && isEditing) {
        setIsEditing(false);
        toast.info("Tournament has started - editing is no longer available");
      }
    };

    // Check immediately
    checkEditPermissions();

    // Then check every minute
    const interval = setInterval(checkEditPermissions, 60000);

    // Cleanup interval on unmount
    return () => clearInterval(interval);
  }, [poolData, validRecords, initialEditable, isEditing]);

  useEffect(() => {
    const fetchPlayerFixtures = async () => {
      if (!poolData?.tournament_fixture?.id) return;
      
      try {
        console.log("Current selected players:", selectedPlayers);
        console.log("Current valid records:", validRecords);

        const { data, error } = await supabase
          .from('playersTournaments')
          .select(`
            id,
            player:playersFixture!inner (
              id,
              player_name,
              world_ranking_position
            )
          `)
          .eq('tournament', poolData.tournament_fixture.id)
          .order('player->world_ranking_position', { ascending: true });

        if (error) throw error;

        // Format data for dropdown
        const fixtures = data.map(item => ({
          key: item.id,
          value: `${item.player.player_name} (${item.player.world_ranking_position})`,
          playerId: item.player.id,
          playerName: item.player.player_name,
          worldRanking: item.player.world_ranking_position
        }));

        console.log("Fetched player fixtures:", fixtures);
        console.log("Tournament ID:", poolData.tournament_fixture.id);
        setPlayerFixtures(fixtures);
      } catch (error) {
        console.error('Error fetching player fixtures:', error);
        toast.error('Error loading players');
      }
    };

    fetchPlayerFixtures();
  }, [poolData?.tournament_fixture?.id, selectedPlayers, validRecords]);

  // Add handler for player selection
  const handlePlayerSelect = (index, event) => {
    const option = event.target.options[event.target.selectedIndex];
    if (!option) return;

    const playerId = Number(option.getAttribute('data-player-id'));
    const playerName = option.getAttribute('data-player-name');
    const worldRanking = Number(option.getAttribute('data-world-ranking'));

    console.log("handlePlayerSelect - Selected values:", {
      index,
      playerId,
      playerName,
      worldRanking,
      option: option.value,
      optionAttributes: {
        playerId: option.getAttribute('data-player-id'),
        playerName: option.getAttribute('data-player-name'),
        worldRanking: option.getAttribute('data-world-ranking')
      }
    });

    // Only update if we have all the required values
    if (playerId && playerName && worldRanking) {
      const newPlayer = {
        id: playerId,
        name: playerName,
        worldRanking: worldRanking,
        lineItemId: selectedPlayers[index]?.lineItemId || null
      };
      
      const newPlayers = [...selectedPlayers];
      newPlayers[index] = newPlayer;

      console.log("handlePlayerSelect - Updating selectedPlayers:", {
        oldValue: selectedPlayers[index],
        newValue: newPlayer,
        fullNewState: newPlayers
      });

      setSelectedPlayers(newPlayers);
    } else {
      console.warn("handlePlayerSelect - Missing required values:", {
        playerId,
        playerName,
        worldRanking
      });
    }
  };

  if (isLoading) {
    return (
      <div className="golf-pools-loading-container">
        <PacmanLoader className="spinner" color="#1a472a" loading={true} size={25} aria-label="Loading Spinner" />
      </div>
    );
  }

  if (!validRecords.length || !validRecords[0]?.pool) {
    return (
      <div className="golf-pools-card">
        <div className="golf-pools-header">
          <div className="golf-pools-header-row">
            <button onClick={() => navigate(-1)} className="golf-pools-back-link">
              <IoChevronBackOutline /> Back
            </button>
            <div style={{ width: '80px' }}></div>
          </div>
          <p className="golf-pools-empty-container">No pool data available.</p>
        </div>
      </div>
    );
  }

  const showTieBreakerRow = validRecords[0].pool.tie_breaker === "Winning Score";
  const poolName = validRecords[0].pool.name || "Pool";
  const ownerName = validRecords[0].team?.owner?.full_name || "Unknown";

  const handleSubmit = async (e) => {
    if (!isEditing) return;
    e.preventDefault();
    
    try {
      setSpinner(true);
      setFormError("");

      const userId = localStorage.getItem('userId');
      if (!userId) {
        throw new Error("User not logged in");
      }

      // Validate all players are selected
      if (selectedPlayers.some(player => !player?.id)) {
        throw new Error("Please select all players");
      }

      // Validate tie breaker score if required
      if (showTieBreakerRow && !tieBreakerScore) {
        throw new Error("Please enter a tie breaker score");
      }

      // Update team record with new tie breaker score if needed
      if (showTieBreakerRow) {
        const { error: teamUpdateError } = await supabase
          .from("team")
          .update({ tie_breaker_score: tieBreakerScore })
          .eq("id", validRecords[0]?.team?.id);

        if (teamUpdateError) throw teamUpdateError;
      }

      // Process each player selection
      for (let i = 0; i < selectedPlayers.length; i++) {
        const player = selectedPlayers[i];
        
        const lineItemData = {
          team: validRecords[0]?.team?.id,
          player: Number(player.id),
          player_world_ranking: player.worldRanking,
          pool: poolData.id,
          owner: Number(userId)  // Add owner field for new line items
        };

        if (player.lineItemId) {
          // Update existing line item
          const { error: updateError } = await supabase
            .from("teamLineItem")
            .update(lineItemData)
            .eq("id", player.lineItemId);

          if (updateError) throw updateError;
        } else {
          // Insert new line item
          const { error: insertError } = await supabase
            .from("teamLineItem")
            .insert(lineItemData);

          if (insertError) throw insertError;
        }
      }

      setIsEditing(false);
      setRefetchTrigger(prev => prev + 1);

      // Fetch updated records to refresh the display
      const { data: updatedRecords, error: fetchError } = await supabase
        .from("teamLineItem")
        .select(`
          id,
          player_world_ranking,
          total_score_to_par,
          player:playersFixture (
            id,
            player_name,
            world_ranking_position
          )
        `)
        .eq("team", validRecords[0]?.team?.id)
        .order('id');

      if (fetchError) throw fetchError;

      // Update selectedPlayers with fresh data
      const updatedPlayers = updatedRecords.map(record => ({
        id: record.player?.id,
        name: record.player?.player_name,
        worldRanking: record.player?.world_ranking_position,
        lineItemId: record.id
      }));
      setSelectedPlayers(updatedPlayers);

      toast.success("Team updated successfully!");
    } catch (error) {
      console.error("Error updating team:", error);
      setFormError(error.message || "An error occurred while updating the team");
      toast.error(error.message || "An error occurred while updating the team");
    } finally {
      setSpinner(false);
    }
  };

  const renderPlayerSelect = (index, record) => {
    const selectedId = selectedPlayers[index]?.id;
    const currentPlayer = record.player;
    
    console.log("Rendering select for:", {
      index,
      selectedId,
      currentPlayer,
      availableFixtures: playerFixtures,
      isCurrentPlayerInTournament: playerFixtures.some(f => Number(f.playerId) === Number(currentPlayer?.id))
    });
    
    return (
      <select 
        className="player-select"
        onChange={(e) => handlePlayerSelect(index, e)}
        value={selectedId || ""}
      >
        <option value="" hidden>Select a Player</option>
        {currentPlayer && !playerFixtures.some(f => Number(f.playerId) === Number(currentPlayer.id)) && (
          <option 
            value={currentPlayer.id} 
            disabled
            style={{color: 'red'}}
          >
            {currentPlayer.player_name} (Not in tournament)
          </option>
        )}
        {playerFixtures.map((fixture) => {
          const fixtureId = Number(fixture.playerId);
          return (
            <option
              key={fixture.key}
              value={fixtureId}
              data-player-id={fixtureId}
              data-player-name={fixture.playerName}
              data-world-ranking={fixture.worldRanking}
              disabled={isPlayerSelected(fixtureId, selectedPlayers, index)}
            >
              {fixture.value}
            </option>
          );
        })}
      </select>
    );
  };

  return (
    <div className="golf-pools-container">
      <div className="golf-pools-header">
        <div className="golf-pools-header-row">
          <button onClick={() => navigate(-1)} className="golf-pools-back-link">
            <IoChevronBackOutline /> Back
          </button>
          <h1 className="golf-pools-title">{poolName}</h1>
          {canShowEditButton && (
            <button 
              onClick={() => setIsEditing(!isEditing)} 
              className="golf-pools-back-link"
            >
              {isEditing ? 'Cancel' : 'Edit'}
            </button>
          )}
        </div>
      </div>

      <div className="golf-pools-sub-title">
        <h2>Team: {ownerName}</h2>
      </div>

      <div className="golf-pools-leaderboard-data">
        <div className="golf-pools-leaderboard-item">
          <span className="golf-pools-leaderboard-label">Tournament Status:</span>
          <span className="golf-pools-leaderboard-value">{validRecords[0].pool.tournament_status || "--"}</span>
        </div>
        <div className="golf-pools-leaderboard-item">
          <span className="golf-pools-leaderboard-label">Round Status:</span>
          <span className="golf-pools-leaderboard-value">{validRecords[0].pool.round_status || "--"}</span>
        </div>
        <div className="golf-pools-leaderboard-item">
          <span className="golf-pools-leaderboard-label">Current Round:</span>
          <span className="golf-pools-leaderboard-value">{validRecords[0].pool.current_round || "--"}</span>
        </div>
        <div className="golf-pools-leaderboard-item">
          <span className="golf-pools-leaderboard-label">Cut Line:</span>
          <span className="golf-pools-leaderboard-value">{validRecords[0].pool.cut_value || "--"}</span>
        </div>
      </div>

      <div className="golf-pools-table-wrapper">
        <table className="golf-pools-table">
          <thead>
            <tr>
              <th>Position</th>
              <th>Player</th>
              <th>World Ranking</th>
              <th>To Par</th>
            </tr>
          </thead>
          <tbody>
            {selectedPlayers.map((player, index) => {
              console.log("Rendering player row:", {
                index,
                player,
                isEditing,
                validRecord: validRecords[index]
              });
              
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    {isEditing ? (
                      <select 
                        className="player-select"
                        onChange={(e) => handlePlayerSelect(index, e)}
                        value={player?.id?.toString() || ""}
                      >
                        <option value="" hidden>Select a Player</option>
                        {playerFixtures.map((fixture) => {
                          console.log("Rendering option:", {
                            fixture,
                            isSelected: fixture.playerId?.toString() === player?.id?.toString(),
                            isDisabled: isPlayerSelected(fixture.playerId, selectedPlayers, index)
                          });
                          
                          return (
                            <option
                              key={fixture.key}
                              value={fixture.playerId?.toString()}
                              data-player-id={fixture.playerId}
                              data-player-name={fixture.playerName}
                              data-world-ranking={fixture.worldRanking}
                              disabled={isPlayerSelected(fixture.playerId, selectedPlayers, index)}
                            >
                              {fixture.value}
                            </option>
                          );
                        })}
                      </select>
                    ) : (
                      <span>{player?.name || "Not Selected"}</span>
                    )}
                  </td>
                  <td>{player?.worldRanking || "--"}</td>
                  <td className={`score-cell ${getScoreClass(validRecords[index]?.total_score_to_par)}`}>
                    {validRecords[index]?.total_score_to_par || "--"}
                  </td>
                </tr>
              );
            })}
            {showTieBreakerRow && (
              <tr className="tie-breaker-row">
                <td colSpan="3" className="tie-breaker-label">Tie Breaker Score:</td>
                <td>
                  {isEditing ? (
                    <input
                      type="number"
                      min="0"
                      value={tieBreakerScore}
                      onChange={(e) => setTieBreakerScore(e.target.value)}
                      className="tie-breaker-input"
                    />
                  ) : (
                    validRecords[0].team?.tie_breaker_score || "--"
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {isEditing && (
        <form onSubmit={handleSubmit} className="golf-pools-actions">
          <button type="submit" className="golf-pools-action-button">
            Save Team <TfiSave />
          </button>
        </form>
      )}
    </div>
  );
});

export default TeamViewForm; 