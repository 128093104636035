import React from "react";
import "./ModalNavBar.css";
import { IoSaveOutline } from "react-icons/io5";
import { IoMdAdd, IoMdPersonAdd } from "react-icons/io";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { MdOutlineEmail } from "react-icons/md";
import { TfiSave } from "react-icons/tfi";

const PoolModalNavBar = ({ 
  activeSection, 
  isPoolOwner, 
  poolRecord, 
  handleSave, 
  mode,
  canEdit,
  showSaveButton,
  showInviteButtons,
  onAddEmail,
  onSendInvites,
  isSubmitting
}) => {
  return (
    <div className="pool-modal-nav-bar">
      <div className="nav-bar-buttons">
        {showSaveButton && (
          <div className="center-button">
            <button
              type="button"
              name="save"
              className="modal-nav-button primary"
              onClick={handleSave}
              disabled={!canEdit}
            >
              Save <IoSaveOutline className="golfIcon" />
            </button>
          </div>
        )}
        {showInviteButtons && (
          <div className="invite-buttons-container">
            <button
              type="button"
              name="addEmail"
              className="add-email-button"
              onClick={onAddEmail}
              disabled={isSubmitting}
            >
              <IoMdAdd size={20} />
              Add Another Email
            </button>
            <button 
              type="submit" 
              className={`submit-invite-button ${isSubmitting ? 'submitting' : ''}`}
              disabled={isSubmitting}
              onClick={onSendInvites}
            >
              {isSubmitting ? (
                <>
                  <AiOutlineLoading3Quarters className="loading-icon" size={20} />
                  Sending Invites...
                </>
              ) : (
                <>
                  <IoMdPersonAdd size={20} />
                  Send Invites
                </>
              )}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PoolModalNavBar; 