import { useState, useEffect, useCallback } from "react";
import { supabase } from "../ReusableComponents/supabaseClient";
import { toast } from "react-toastify";
import moment from "moment";
import "moment-timezone";
const isValidPoolNumber = (number) => /^\d+$/.test(number);

export const fetchPoolsForUser = async (userId) => {
  const formattedDate = getTournamentTimeReference();

  try {
    const { data, error } = await supabase
      .from("team")
      .select(
        `
      pool(
        id,
        name,
        tournament_fixture:pool_tournament_fixture_fkey(end_date)
      )
    `
      )
      .eq("owner", userId)
      .order("pool", { ascending: true });

    if (error) {
      toast.error(`Error fetching pools: ${error.message}`);
      throw error;
    }

    const filteredData = data.filter((item) => {
      const poolEndDate = new Date(item.pool.tournament_fixture.end_date);
      return poolEndDate >= new Date(formattedDate);
    });

    const options = filteredData.map((item) => ({
      value: item.pool.id,
      label: `Pool #${item.pool.id}`,
    }));

    return options;
  } catch (error) {
    toast.error(`Error fetching pools: ${error.message}`);
    throw error;
  }
};

export const useFetchPoolAndLineItemData = (poolNumber, refetchTrigger, teamId = null) => {
  const [poolData, setPoolData] = useState(null);
  const [teamLineItem, setTeamLineItem] = useState(null);
  const [hasLineItems, setHasLineItems] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isOwner, setIsOwner] = useState(false);
  const [teamStatus, setTeamStatus] = useState(null);
  const currentUserId = Number(localStorage.getItem("userId"));

  const handleError = (err) => {
    setError(err.message);
    setPoolData(null);
    setTeamLineItem(null);
    setHasLineItems(false);
    setIsOwner(false);
    setTeamStatus(null);
  };

  const convertScore = (score) => {
    if (score === "E") return 0;
    if (score.includes("+")) return parseInt(score.replace("+", ""), 10);
    if (score.includes("-")) return parseInt(score, 10);
    return parseInt(score, 10);
  };

  const sortLineItems = (data) => {
    return data.sort((a, b) => {
      const scoreA = convertScore(a.total_score_to_par);
      const scoreB = convertScore(b.total_score_to_par);
      if (scoreA !== scoreB) return scoreA - scoreB;
      return a.player_world_ranking - b.player_world_ranking;
    });
  };

  const fetchData = useCallback(async () => {
    setError(false);
    setLoading(true);
    setPoolData(null);
    setTeamLineItem(null);
    setHasLineItems(false);
    setIsOwner(false);
    setTeamStatus(null);

    if (poolNumber && !isValidPoolNumber(poolNumber)) {
      setError("Invalid pool number");
      setLoading(false);
      return;
    }

    try {
      // First get the pool data
      const { data: poolData, error: poolError } = await supabase
        .from("pool")
        .select(`
          id, 
          max_players, 
          tie_breaker, 
          name, 
          round_status, 
          current_round, 
          cut_value, 
          time_zone, 
          tournament_status,
          tournament_fixture,
          tournament_fixture (
            id,
            name,
            start_date
          ),
          owner (
            id,
            full_name,
            email
          )
        `)
        .eq("id", poolNumber)
        .single();

      if (poolError) {
        toast.error(`Error fetching pool: ${poolError.message}`);
        throw poolError;
      }

      if (!poolData) {
        setError("Pool does not exist");
        return;
      }

      // Get the team ID for the current user in this pool if no teamId is provided
      let effectiveTeamId = teamId;
      if (!effectiveTeamId) {
        const { data: teamData, error: teamError } = await supabase
          .from("team")
          .select("id, owner, active")
          .eq("pool", poolNumber)
          .eq("owner", currentUserId)
          .single();

        if (teamData) {
          effectiveTeamId = teamData.id;
          setIsOwner(true);
          setTeamStatus(teamData.active);
        }
      } else {
        // If teamId was provided, check ownership
        const { data: teamData, error: teamError } = await supabase
          .from("team")
          .select("owner, active")
          .eq("id", effectiveTeamId)
          .single();

        if (teamData) {
          setIsOwner(teamData.owner === currentUserId);
          setTeamStatus(teamData.active);
        }
      }

      // Then fetch line items if we have a team
      let lineItemData = [];
      if (effectiveTeamId) {
        const { data, error: lineItemError } = await supabase
          .from("teamLineItem")
          .select(`
            id, 
            pool (
              id,
              name,
              round_status,
              current_round,
              cut_value,
              tournament_status
            ),
            player(id, player_name, player_Id, world_ranking_position), 
            player_world_ranking, 
            total_score_to_par,
            team (
              id, 
              tie_breaker_score,
              owner:user!inner (
                id,
                full_name,
                email,
                can_create_late_team
              )
            )
          `)
          .eq("pool", poolNumber)
          .eq("team", effectiveTeamId);

        console.log("lineItemData:", data);

        if (lineItemError) {
          toast.error(`Error fetching line items: ${lineItemError.message}`);
          throw lineItemError;
        }

        lineItemData = data;
      }

      // Set the state
      setPoolData(poolData);
      if (lineItemData.length > 0) {
        const sortedLineItems = sortLineItems(lineItemData);
        setTeamLineItem(sortedLineItems);
        setHasLineItems(true);
      } else {
        setTeamLineItem([]);
        setHasLineItems(false);
      }
    } catch (err) {
      toast.error(`Error: ${err.message}`);
      handleError(err.message);
    } finally {
      setLoading(false);
    }
  }, [poolNumber, teamId, currentUserId]);

  useEffect(() => {
    if (poolNumber) {
      fetchData();
    }
    const lineItemsSubscription = supabase
      .channel("lineItemsSubscription")
      .on("postgres_changes", { event: "UPDATE", schema: "public", table: "teamLineItem", filter: `pool=eq.${poolNumber}` }, (payload) => {
        fetchData();
      })
      .subscribe();

    return () => {
      supabase.removeChannel(lineItemsSubscription);
    };
  }, [fetchData, refetchTrigger, poolNumber]);

  return { poolData, teamLineItem, hasLineItems, loading, error, isOwner, teamStatus };
};

export const useGetPlayerTournamentFixtures = (poolData) => {
  const [playerFixtures, setPlayerFixtures] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!poolData) {
        setError("No pool data available");
        setLoading(false);
        return;
      }

      let tournamentId;
      if (poolData.tournament_fixture && typeof poolData.tournament_fixture === 'object') {
        tournamentId = poolData.tournament_fixture.id;
      } else {
        tournamentId = poolData.tournament_fixture;
      }

      if (!tournamentId) {
        setError("No tournament is currently available for this pool");
        setLoading(false);
        return;
      }

      try {
        const { data, error } = await supabase
          .from("playersTournaments")
          .select(`
            id,
            player:player_id(
              id,
              player_name,
              world_ranking_position
            )
          `)
          .eq("tournament", tournamentId)
          .limit(200);

        if (error) throw error;

        if (!data || data.length === 0) {
          setError("Fore! 🏌️ The tournament field has not been set. Please try again on the Monday the week of the tournament ⛳");
          setPlayerFixtures([]);
          setLoading(false);
          return;
        }

        const filteredData = data.filter(
          (item) =>
            item.player &&
            item.player.player_name &&
            item.player.world_ranking_position !== null &&
            item.player.world_ranking_position !== ""
        );

        const sortedData = filteredData.sort((a, b) => {
          const rankA = parseInt(a.player.world_ranking_position) || 999;
          const rankB = parseInt(b.player.world_ranking_position) || 999;
          return rankA - rankB;
        });

        const transformedData = transformData(sortedData);
        setPlayerFixtures(transformedData);
        localStorage.setItem("playerFixtures", JSON.stringify(transformedData));
      } catch (error) {
        toast.error(`Error fetching player fixtures: ${error.message}`);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [poolData]);

  return { playerFixtures, loading, error };
};

export const useInsertTeamRecord = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const insertTeam = async (poolId) => {
    setLoading(true);
    setError(null);

    try {
      const { data, error } = await supabase
        .from("team")
        .insert([
          {
            owner: Number(localStorage.getItem("userId")),
            pool: poolId,
          },
        ])
        .select();

      if (error) throw error;
      return data[0];
    } catch (error) {
      toast.error(`Error inserting team: ${error.message}`);
      setError(error.message);
      return null;
    } finally {
      setLoading(false);
    }
  };

  return { insertTeam, loading, error };
};

export const useInsertTeamLineItems = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const insertLineItems = async (lineItems) => {
    setLoading(true);
    setError(null);

    try {
      const { data, error } = await supabase
        .from("teamLineItem")
        .insert(lineItems)
        .select();

      if (error) throw error;
      return data;
    } catch (error) {
      toast.error(`Error inserting line items: ${error.message}`);
      setError(error.message);
      return null;
    } finally {
      setLoading(false);
    }
  };

  return { insertLineItems, loading, error };
};

export const fetchTeamId = async (poolId) => {
  try {
    const { data, error } = await supabase
      .from("team")
      .select("id")
      .eq("pool", poolId)
      .eq("owner", Number(localStorage.getItem("userId")))
      .single();

    if (error) throw error;
    return data?.id;
  } catch (error) {
    toast.error(`Error fetching team ID: ${error.message}`);
    return null;
  }
};

export const useUpdateTeamLineItem = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const updateLineItem = async (id, updates) => {
    setLoading(true);
    setError(null);

    try {
      const { data, error } = await supabase
        .from("teamLineItem")
        .update(updates)
        .eq("id", id)
        .select();

      if (error) throw error;
      return data;
    } catch (error) {
      toast.error(`Error updating line item: ${error.message}`);
      setError(error.message);
      return null;
    } finally {
      setLoading(false);
    }
  };

  return { updateLineItem, loading, error };
};

export const useUpdateTeamRecord = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const updateTeam = async (id, updates) => {
    setLoading(true);
    setError(null);

    try {
      const { data, error } = await supabase
        .from("team")
        .update(updates)
        .eq("id", id)
        .select();

      if (error) throw error;
      return data;
    } catch (error) {
      toast.error(`Error updating team: ${error.message}`);
      setError(error.message);
      return null;
    } finally {
      setLoading(false);
    }
  };

  return { updateTeam, loading, error };
};

export const setTeam = (poolData, tieBreakerScore) => {
  return {
    owner: Number(localStorage.getItem("userId")),
    pool: poolData.id,
    tie_breaker_score: tieBreakerScore,
  };
};

export const setTeamLineItems = (selectedPlayers, poolId, teamId) => {
  return selectedPlayers.map((player) => ({
    team: teamId,
    pool: poolId,
    player: player.value,
    player_world_ranking: player.world_ranking,
  }));
};

export const formatDate = (dateString) => {
  return new Date(dateString).toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
};

const transformData = (data) =>
  data.map((item) => ({
    value: item.player.id,
    label: item.player.player_name,
    world_ranking: item.player.world_ranking_position,
  }));

function getCurrentCentralTime() {
  return moment().tz("America/Chicago");
}

export const isOptionDisabled = (optionPlayerFixture, selectedPlayers, currentDropdownIndex) => {
  return selectedPlayers.some((player, index) => player?.value === optionPlayerFixture.value && index !== currentDropdownIndex);
};

export const PlayerSelectDisabled = (optionPlayerFixture, selectedPlayers, currentDropdownIndex) => {
  return selectedPlayers.some((player, index) => player?.value === optionPlayerFixture.value && index !== currentDropdownIndex);
};

export const editOptionDisabled = (optionPlayerId, selections, currentDropdownIndex) => {
  return selections.some((selection, index) => selection?.value === optionPlayerId && index !== currentDropdownIndex);
};

export const validateSelections = (selectedPlayers, poolData, tieBreakerScore) => {
  if (!poolData) {
    return "Pool data is required";
  }

  if (selectedPlayers.length !== poolData.max_players) {
    return `Please select exactly ${poolData.max_players} players`;
  }

  if (selectedPlayers.some((player) => !player)) {
    return "All player selections are required";
  }

  if (poolData.tie_breaker && !tieBreakerScore) {
    return "Tie breaker score is required";
  }

  return null;
};

function getTournamentTimeReference() {
  const currentTime = getCurrentCentralTime();
  const dayOfWeek = currentTime.day();
  const hour = currentTime.hour();
  return currentTime.format();
}

export const canEditRecord = (pool) => {
  const timeZone = pool.time_zone;
  if (!timeZone) {
    toast.error('No timezone specified in pool data');
    return false;
  }

  const now = moment().tz(timeZone);
  const firstTeeTime = pool.first_tee_time ? moment.tz(pool.first_tee_time, timeZone) : null;

  if (!firstTeeTime) {
    return true;
  }

  return now.isBefore(firstTeeTime);
};
