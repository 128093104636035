import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../ReusableComponents/supabaseClient';
import { IoChevronBackOutline } from 'react-icons/io5';
import { PacmanLoader } from "react-spinners";
import moment from "moment";
import './Leaderboard.css';
// import '../Pool/PoolLink.css';

const AllLeaderboard = () => {
  const [pools, setPools] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const loggedInUser = Number(localStorage.getItem("userId"));

  const getStatusClass = (status) => {
    switch (status) {
      case 'Not Started':
        return 'status-not-started';
      case 'In Progress':
        return 'status-in-progress';
      case 'Suspended':
        return 'status-suspended';
      case 'Official':
      case 'Complete':
        return 'status-official';
      default:
        return 'status-not-started';
    }
  };

  useEffect(() => {
    const fetchPools = async () => {
      try {
        setLoading(true);
        
        // First get pools where user is owner
        const { data: ownerPools, error: ownerError } = await supabase
          .from('pool')
          .select(`
            id,
            name,
            tournament_status,
            current_round,
            round_status,
            tournament_fixture (
              start_date,
              name
            )
          `)
          .eq('owner', loggedInUser)
          .order('created_date', { ascending: false });

        if (ownerError) throw ownerError;

        // Then get pools where user is invited
        const { data: invitedPools, error: inviteError } = await supabase
          .from('InviteLink')
          .select(`
            pool (
              id,
              name,
              tournament_status,
              current_round,
              round_status,
              tournament_fixture (
                start_date,
                name
              )
            )
          `)
          .eq('person', loggedInUser)
          .order('created_date', { ascending: false });

        if (inviteError) throw inviteError;

        // Combine and deduplicate pools
        const allPools = [
          ...(ownerPools || []),
          ...(invitedPools?.map(ip => ip.pool) || [])
        ];

        // Remove duplicates based on pool id
        const uniquePools = Array.from(new Map(allPools.map(pool => [pool.id, pool])).values());

        // Sort by created date (most recent first)
        const sortedPools = uniquePools.sort((a, b) => {
          const dateA = new Date(a.tournament_fixture?.start_date || 0);
          const dateB = new Date(b.tournament_fixture?.start_date || 0);
          return dateB - dateA;
        });

        setPools(sortedPools || []);
      } catch (error) {
        console.error('Error fetching pools:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPools();
  }, [loggedInUser]);

  if (loading) {
    return (
      <div className="loading-container">
        <PacmanLoader className="spinner" color="#1a472a" loading={true} size={25} aria-label="Loading Spinner" />
      </div>
    );
  }

  const handleRowClick = (poolId) => {
    navigate(`/leaderboard/${poolId}`);
  };

  return (
    <div className="pool-link-table-card">
      <div className="pool-link-table-container">
        <div className="pool-header">
          <div className="pool-header-row">
            <button onClick={() => navigate(-1)} className="back-link">
              <IoChevronBackOutline /> Back
            </button>
            <h1 className="pool-title">My Leaderboards</h1>
            <div style={{ width: '80px' }}></div>
          </div>
        </div>
        {pools.length > 0 ? (
          <table className="pool-link-table">
            <thead>
              <tr>
                <th className="pool-link-th pool-number">Pool #</th>
                <th className="pool-link-th tournament">Tournament</th>
                <th className="pool-link-th start-date">Start Date</th>
                <th className="pool-link-th text-center">Round</th>
                <th className="pool-link-th text-center">Round Status</th>
                <th className="pool-link-th status">Status</th>
              </tr>
            </thead>
            <tbody>
              {pools.map((pool) => (
                <tr 
                  key={pool.id} 
                  onClick={() => handleRowClick(pool.id)}
                  className="clickable-row"
                >
                  <td className="pool-link-td">{pool.id}</td>
                  <td className="pool-link-td">{pool.tournament_fixture?.name || pool.name}</td>
                  <td className="pool-link-td">
                    {moment(pool.tournament_fixture?.start_date).format("MMM Do, YYYY")}
                  </td>
                  <td className="pool-link-td text-center">
                    {pool.current_round || '-'}
                  </td>
                  <td className="pool-link-td text-center">
                    <div className="status-badge-container">
                      <span className={`status-badge ${getStatusClass(pool.round_status)}`}>
                        {pool.round_status || 'Not Started'}
                      </span>
                    </div>
                  </td>
                  <td className="pool-link-td text-center">
                    <div className="status-badge-container">
                      <span className={`status-badge ${getStatusClass(pool.tournament_status)}`}>
                        {pool.tournament_status || 'Not Started'}
                      </span>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="no-pool-link-data-container">
            <p>No leaderboards available</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default AllLeaderboard; 