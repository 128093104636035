import React, { useEffect, useState } from "react";
import { fetchTournamentOptions, validateFormData } from "./PoolModalHelper";
import moment from "moment";
import './PoolForm.css';

const PoolForm = ({ handleClose, poolData, mode, isPoolOwner, setFormData, canEdit, activeTab, errors, setErrors }) => {
  const initialFormState = {
    tournament: poolData?.tournament_fixture?.id || "",
    teamSize: poolData?.max_players || "5",
    numPlayersScored: poolData?.num_player_used_for_scoring || "5",
    allMakeCut: poolData?.all_players_must_make_cut || false,
    tieBreaker: poolData?.tie_breaker || "Average World Ranking",
    poolId: poolData?.id || null,
  };
  
  const [formState, setFormState] = useState(initialFormState);
  const [tournaments, setTournaments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadTournaments = async () => {
      setIsLoading(true);
      try {
        const tournamentData = await fetchTournamentOptions(poolData?.tournament_fixture?.tournament_id);
        setTournaments(tournamentData);

        if (poolData?.tournament_fixture?.tournament_id) {
          const matchingTournament = tournamentData.find(
            t => t.tournament_id === poolData.tournament_fixture.tournament_id
          );
          if (matchingTournament) {
            setFormState(prev => ({
              ...prev,
              tournament: matchingTournament.id
            }));
          }
        }
      } catch (error) {
        console.error("Error loading tournaments:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (activeTab === "edit" || !activeTab) {
      loadTournaments();
    }
  }, [activeTab, poolData?.tournament_fixture?.tournament_id]);

  useEffect(() => {
    if (setFormData) {
      setFormData(formState);
    }
  }, [formState, setFormData]);

  const handleInputChange = (e) => {
    if (!canEdit) return;
    
    const { name, value, type, checked } = e.target;
    let newValue = type === "checkbox" ? checked : value;
    
    // Add validation for number inputs
    if (type === "number") {
      const numValue = parseInt(value);
      if (numValue < 1) {
        newValue = "1";
      } else if (numValue > 15) {
        newValue = "15";
      }
    }
    
    setFormState(prev => ({
      ...prev,
      [name]: newValue
    }));

    // Clear error for this field when it's changed
    if (errors && errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: undefined
      }));
    }
  };

  // const getDisabledMessage = () => {
  //   if (!canEdit) {
  //     if (poolData?.tournament_fixture?.start_date && moment(poolData.tournament_fixture.start_date).isBefore(moment())) {
  //       return "Tournament has already started. Editing is disabled.";
  //     }
  //     return "Maximum number of teams reached. Editing is disabled.";
  //   }
  //   return null;
  // };

  // const disabledMessage = getDisabledMessage();

  return (
    <div className={`pool-form-container ${(mode === 'edit' || mode === 'insert') && activeTab === 'edit' ? 'pool-modal-body' : ''}`}>
      {!canEdit && mode === 'edit' && (
        <p className="edit-disabled-message">
          Tournament has already started. Editing is disabled.
        </p>
      )}
      <form className="pool-form">
        <div className="pool-form-group">
          <label className="form-label" htmlFor="tournament">Tournament:</label>
          <select
            id="tournament"
            name="tournament"
            value={formState.tournament}
            onChange={handleInputChange}
            disabled={!canEdit}
            required
            className={errors?.tournament ? 'form-select error' : 'form-select'}
          >
            <option value="">Select Tournament</option>
            {tournaments.map((tournament) => (
              <option key={tournament.id} value={tournament.id}>
                {tournament.name} - {moment(tournament.start_date).format("MMM DD, YYYY")}
              </option>
            ))}
          </select>
          {errors?.tournament && <span className="error-message">{errors.tournament}</span>}
        </div>

        <div className="pool-form-group">
          <label className="form-label" htmlFor="teamSize">Players Per Team:</label>
          <input
            type="number"
            id="teamSize"
            name="teamSize"
            value={formState.teamSize}
            onChange={handleInputChange}
            disabled={!canEdit}
            min="1"
            max="15"
            step="1"
            required
            className={errors?.teamSize ? 'form-input error' : 'form-input'}
          />
          {errors?.teamSize && <span className="error-message">{errors.teamSize}</span>}
        </div>

        <div className="pool-form-group">
          <label className="form-label" htmlFor="numPlayersScored">Players Used For Scoring:</label>
          <input
            type="number"
            id="numPlayersScored"
            name="numPlayersScored"
            value={formState.numPlayersScored}
            onChange={handleInputChange}
            disabled={!canEdit}
            min="1"
            max="15"
            step="1"
            required
            className={errors?.numPlayersScored ? 'form-input error' : 'form-input'}
          />
          {errors?.numPlayersScored && <span className="error-message">{errors.numPlayersScored}</span>}
        </div>

        <div className="pool-form-group">
          <label className="form-label" htmlFor="tieBreaker">Tie Breaker:</label>
          <select
            id="tieBreaker"
            name="tieBreaker"
            value={formState.tieBreaker}
            onChange={handleInputChange}
            disabled={!canEdit}
            required
            className={errors?.tieBreaker ? 'form-select error' : 'form-select'}
          >
            <option value="">Select Tie Breaker</option>
            <option value="Average World Ranking">Average World Ranking</option>
            <option value="Winning Score">Winning Score</option>
          </select>
          {errors?.tieBreaker && <span className="error-message">{errors.tieBreaker}</span>}
        </div>

        <div className="form-checkbox-group">
          <label htmlFor="allMakeCut" className="form-checkbox-label">
            <input
              type="checkbox"
              id="allMakeCut"
              name="allMakeCut"
              checked={formState.allMakeCut}
              onChange={handleInputChange}
              disabled={!canEdit}
              className="form-checkbox"
            />
            <span>Require all players to make the cut to be eligible for scoring</span>
          </label>
        </div>
      </form>
    </div>
  );
};

export default PoolForm;
