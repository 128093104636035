import React, { useState, useEffect, useCallback } from "react";
import { IoMdClose } from "react-icons/io";
import { supabase } from "../Utilities/supabaseClient";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import './PoolDetailsModal.css';

const InviteToPool = ({ poolId, onAddEmail, onSendInvites, isSubmitting }) => {
  const [emails, setEmails] = useState(() => [""]); 
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleEmailChange = (index, value) => {
    const newEmails = [...emails];
    newEmails[index] = value;
    setEmails(newEmails);
  };

  const addEmailField = () => {
    setEmails(prev => [...prev, ""]);
  };

  const removeEmailField = (index) => {
    if (emails.length > 1) {
      const newEmails = emails.filter((_, i) => i !== index);
      setEmails(newEmails.length > 0 ? newEmails : [""]);
    }
  };

  const handleSubmit = useCallback(async () => {
    setError("");
    setSuccess("");

    const validEmails = emails.filter((email) => email.trim() !== "");
    if (validEmails.length === 0) {
      setError("Please enter at least one email address");
      return false;
    }

    try {
      const currentDate = moment().tz("America/Chicago").utc().format();
      
      const invitePromises = validEmails.map(async (email) => {
        const { data: userData, error: userError } = await supabase
          .from("user")
          .select("id")
          .eq("email", email)
          .single();

        if (userError) {
          toast.error(`Error finding user for ${email}: ${userError.message}`);
          throw new Error(`User not found for ${email}`);
        }

        const { data: team, error: teamError } = await supabase
          .from("team")
          .insert([
            {
              owner: userData.id,
              pool: poolId,
              created_date: currentDate,
              modified_date: currentDate
            }
          ])
          .select();

        if (teamError) {
          toast.error(`Error creating team for ${email}: ${teamError.message}`);
          throw new Error(`Failed to create team for ${email}`);
        }

        const response = await fetch('/api/email/invitePlayerEmail', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email,
            poolId,
            isResend: false
          }),
        });

        if (!response.ok) {
          throw new Error(`Failed to send invite to ${email}`);
        }
      });

      await Promise.all(invitePromises);
      setSuccess("Invitations sent successfully!");
      setEmails([""]);
      return true;
    } catch (error) {
      setError(error.message || "Failed to send invitations");
      return false;
    }
  }, [emails, poolId]);

  useEffect(() => {
    setEmails([""]);
    return () => setEmails([""]);
  }, []);

  useEffect(() => {
    if (onAddEmail) {
      onAddEmail(addEmailField);
    }
  }, [onAddEmail]);

  useEffect(() => {
    if (onSendInvites) {
      onSendInvites(handleSubmit);
    }
  }, [onSendInvites, handleSubmit]);

  return (
    <div className="invite-form">
      {error && <p className="error-message">{error}</p>}
      {success && <p className="success-message">{success}</p>}
      
      <div className="invite-content">
        <div className="email-input-row">
          <input
            type="email"
            value={emails[0] || ""}
            onChange={(e) => handleEmailChange(0, e.target.value)}
            placeholder="Enter email address"
            className="email-input"
            required
            disabled={isSubmitting}
          />
          <div className="button-spacer" />
        </div>
        
        {emails.slice(1).map((email, index) => (
          <div key={index + 1} className="email-input-row">
            <input
              type="email"
              value={email}
              onChange={(e) => handleEmailChange(index + 1, e.target.value)}
              placeholder="Enter email address"
              className="email-input"
              required
              disabled={isSubmitting}
            />
            <div className="button-spacer">
              <button
                type="button"
                onClick={() => removeEmailField(index + 1)}
                className="email-remove-button"
                aria-label="Remove email"
                disabled={isSubmitting}
              >
                <IoMdClose size={20} />
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InviteToPool;
