import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { IoChevronBackOutline } from "react-icons/io5";
import TeamTable from "./TeamTable";
import { supabase } from "../ReusableComponents/supabaseClient";
import { PacmanLoader } from "react-spinners";
import { toast } from "react-toastify";
import {
  useFetchTeamByPool,
  useFetchTeamLineItemsByPool,
  fetchPoolDetailsById,
} from "./LeaderboardHelper";
import '../styles/golfPoolStyles.css';

function Leaderboards() {
  const { poolId } = useParams();
  const navigate = useNavigate();
  const [poolData, setPoolData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { teams, teamLoading } = useFetchTeamByPool(poolId);
  const { teamLineItems, teamLinesLoading } = useFetchTeamLineItemsByPool(poolId);

  useEffect(() => {
    setLoading(true);
    fetchPoolDetailsById(poolId).then(data => {
      setPoolData(data);
      setLoading(false);
    }).catch(err => {
      console.error("Error fetching pool details:", err.message);
      setError('Failed to fetch pool details');
      toast.error('Failed to fetch pool details: ' + err.message);
      setLoading(false);
    });

    const poolTableSubscription = supabase
      .channel("poolTableSubscription")
      .on(
        "postgres_changes",
        { event: "UPDATE", schema: "public", table: "pool", filter: `id=eq.${poolId}` },
        (payload) => {
          setPoolData(payload.new);
        }
      )
      .subscribe();

    return () => supabase.removeChannel(poolTableSubscription);
  }, [poolId]);

  if (loading || teamLoading || teamLinesLoading) {
    return (
      <div className="golf-pools-loading-container">
        <PacmanLoader className="spinner" color="#1a472a" loading={true} size={25} aria-label="Loading Spinner" />
      </div>
    );
  }

  if (error) {
    return <div className="golf-pools-empty-container">{error}</div>;
  }

  return (
    <div className="golf-pools-card">
      <div className="golf-pools-container">
        {teams.length > 0 ? (
          <>
            {poolData && (
              <>
                <div className="golf-pools-header">
                  <div className="golf-pools-header-row">
                    <button onClick={() => navigate(-1)} className="golf-pools-back-link">
                      <IoChevronBackOutline /> Back
                    </button>
                    <h2 className="golf-pools-title">{poolData.name}</h2>
                    <div style={{ width: '80px' }}></div>
                  </div>
                </div>
                <div className="golf-pools-leaderboard-data">
                  <LeaderboardDataItem label="Tournament Status" value={poolData.tournament_status} />
                  <LeaderboardDataItem label="Round Status" value={poolData.round_status} />
                  <LeaderboardDataItem label="Current Round" value={poolData.current_round} />
                  <LeaderboardDataItem label="Cut Value" value={poolData.cut_value} />
                </div>
              </>
            )}
            <TeamTable teams={teams} teamLineItems={teamLineItems} />
          </>
        ) : (
          <>
            <div className="golf-pools-header">
              <div className="golf-pools-header-row">
                <button onClick={() => navigate(-1)} className="golf-pools-back-link">
                  <IoChevronBackOutline /> Back
                </button>
                <h2 className="golf-pools-title">Leaderboard</h2>
                <div style={{ width: '80px' }}></div>
              </div>
            </div>
            <div className="golf-pools-empty-container">
              Fore! 🏌️ Looks like there are no teams registered for this pool yet ⛳
            </div>
          </>
        )}
      </div>
    </div>
  );
}

const LeaderboardDataItem = ({ label, value }) => (
  <div className="golf-pools-leaderboard-item">
    <span className="golf-pools-leaderboard-label">{label}:</span>
    <span className="golf-pools-leaderboard-value">{value}</span>
  </div>
);

export default Leaderboards; 