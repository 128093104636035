import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { AuthProvider, ProtectedRoute } from "./Utilities/AuthContext.js";
import Login from "./LoginComponents/Login";
import ResetPassword from "./LoginComponents/ResetPassword";
import BackgroundTheme from "./ReusableComponents/BackgroundTheme";
import Navbar from "./ReusableComponents/Navbar";
import AllPools from "./Pool/AllPools.js";
import Team from "./Team/Team.js";
import Leaderboard from "./Leaderboard/Leaderboard.js";
import AllLeaderboard from "./Leaderboard/AllLeaderboards.js";
import Dashboard from "./DashboardComponents/Dashboard.js";
import PoolPage from "./Pool/PoolPage.js";
import AllTeamsView from './Team/AllTeamsView.js';

function App() {
  const location = useLocation();
  const showNav = location.pathname !== "/login" && location.pathname !== "/" && location.pathname !== "/reset-password";
 
  return (
    <AuthProvider>
      {showNav && <Navbar />}
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <BackgroundTheme>
                <Dashboard />
              </BackgroundTheme>
            </ProtectedRoute>
          }
        />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/home" element={
          <ProtectedRoute>
            <BackgroundTheme>
              <Dashboard />
            </BackgroundTheme>
          </ProtectedRoute>
        } />
        <Route path="/pools" element={
          <ProtectedRoute>
            <BackgroundTheme>
              <AllPools />
            </BackgroundTheme>
          </ProtectedRoute>
        } />
        <Route path="/teams" element={
          <ProtectedRoute>
            <BackgroundTheme>
              <AllTeamsView />
            </BackgroundTheme>
          </ProtectedRoute>
        } />
        <Route path="/teams/:poolId" element={
          <ProtectedRoute>
            <BackgroundTheme>
              <Team />
            </BackgroundTheme>
          </ProtectedRoute>
        } />
        <Route path="/tournaments" element={
          <ProtectedRoute>
            <BackgroundTheme>
              <Leaderboard />
            </BackgroundTheme>
          </ProtectedRoute>
        } />
        <Route path="/tournaments/:tournamentId" element={
          <ProtectedRoute>
            <BackgroundTheme>
              <Leaderboard />
            </BackgroundTheme>
          </ProtectedRoute>
        } />
        <Route path="/pool/:poolId" element={
          <ProtectedRoute>
            <BackgroundTheme>
              <PoolPage />
            </BackgroundTheme>
          </ProtectedRoute>
        } />
        <Route path="/pool/:poolId/team/:teamId" element={
          <ProtectedRoute>
            <BackgroundTheme>
              <Team />
            </BackgroundTheme>
          </ProtectedRoute>
        } />
        <Route path="/leaderboard" element={
          <ProtectedRoute>
            <BackgroundTheme>
              <AllLeaderboard />  
            </BackgroundTheme>
          </ProtectedRoute>
        } />
        <Route path="/leaderboard/:poolId" element={
          <ProtectedRoute>
            <BackgroundTheme>
              <Leaderboard />
            </BackgroundTheme>
          </ProtectedRoute>
        } />
      </Routes>
    </AuthProvider>
  );
}

export default App;
