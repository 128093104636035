import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaUsers, FaTrophy, FaPlus } from 'react-icons/fa';
import { IoGolfOutline } from 'react-icons/io5';
import { supabase } from '../ReusableComponents/supabaseClient';
import PoolDetailsModal from '../Pool/PoolDetailsModal';
import { toast } from 'react-toastify';
import './Dashboard.css';

function Dashboard() {
  const [pools, setPools] = useState([]);
  const [teams, setTeams] = useState([]);
  const [tournaments, setTournaments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const userId = localStorage.getItem("userId");
  const userName = localStorage.getItem("userName");

  const fetchDashboardData = async () => {
    if (!userId) return;
    
    try {
      // Fetch pools through InviteLink table
      const { data: poolsData, error: poolsError } = await supabase
        .from('InviteLink')
        .select(`
          id,
          pool (
            id,
            name,
            tournament_fixture,
            number_of_teams,
            all_players_completed,
            tournament_status,
            tournamentsFixture:tournament_fixture (
              id,
              tournament_id
            )
          )
        `)
        .eq('person', userId)
        .order('created_date', { ascending: false })
        .limit(3);

      if (poolsError) throw poolsError;

      // Transform the data to match the expected format
      const transformedPools = poolsData?.map(item => ({
        ...item.pool,
      })) || [];

      // Fetch user's teams
      const { data: teamsData, error: teamsError } = await supabase
        .from('team')
        .select(`
          id,
          owner,
          pool,
          team_world_ranking_average,
          missed_cut,
          pool:pool (
            id,
            name,
            all_players_completed,
            tournament_fixture,
            tournament_status,
            tournamentsFixture:tournament_fixture (
              id,
              tournament_id
            )
          )
        `)
        .eq('owner', userId)
        .order('created_date', { ascending: false })
        .limit(3);

      if (teamsError) throw teamsError;

      // Fetch pools for leaderboards
      const { data: leaderboardsData, error: leaderboardsError } = await supabase
        .from('pool')
        .select(`
          id,
          name,
          number_of_teams,
          all_players_completed,
          tournament_status,
          tournament_fixture,
          tournamentsFixture:tournament_fixture (
            id,
            tournament_id
          )
        `)
        .order('created_date', { ascending: false })
        .limit(3);

      if (leaderboardsError) throw leaderboardsError;

      setPools(transformedPools);
      setTeams(teamsData || []);
      setTournaments(leaderboardsData || []);
    } catch (error) {
      toast.error(`Error fetching dashboard data: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDashboardData();

    // Subscribe to changes in the InviteLink table
    const inviteLinkSubscription = supabase
      .channel('invite_link_changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'InviteLink',
          filter: `person=eq.${userId}`
        },
        (payload) => {
          fetchDashboardData();
        }
      )
      .subscribe();

    // Subscribe to changes in the pool table
    const poolSubscription = supabase
      .channel('pool_changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'pool'
        },
        (payload) => {
          fetchDashboardData();
        }
      )
      .subscribe();

    // Cleanup subscriptions on unmount
    return () => {
      inviteLinkSubscription.unsubscribe();
      poolSubscription.unsubscribe();
    };
  }, [userId]);

  const getStatusClass = (status) => {
    switch (status) {
      case 'Not Started':
        return 'status-not-started';
      case 'In Progress':
        return 'status-in-progress';
      case 'Official':
        return 'status-completed';
      default:
        return 'status-error';
    }
  };

  const renderPoolsList = () => {
    if (loading) return <div className="loading">Loading pools...</div>;
    if (pools.length === 0) return <div className="empty-state">No pools created yet</div>;

    return (
      <div className="dashboard-list">
        {pools.map(pool => (
          <Link key={pool.id} to={`/pool/${pool.id}`} className="dashboard-item">
            <div className="item-header">
              <h3 className="truncate-text">{pool.name}</h3>
              <div className={`item-status ${getStatusClass(pool.tournament_status)}`}>
                {pool.tournament_status || 'Unknown'}
              </div>
            </div>
            <div className="item-details">
              <span>Teams: {pool.number_of_teams || 0}</span>
            </div>
          </Link>
        ))}
      </div>
    );
  };

  const renderTeamsList = () => {
    if (loading) return <div className="loading">Loading teams...</div>;
    if (teams.length === 0) return <div className="empty-state">No teams found</div>;

    return (
      <div className="dashboard-list">
        {teams.map(team => (
          <Link key={team.id} to={`/pool/${team.pool?.id}/team/${team.id}`} className="dashboard-item">
            <div className="item-header">
              <h3 className="truncate-text">{team.pool?.name}</h3>
              <div className={`item-status ${getStatusClass(team.pool?.tournament_status)}`}>
                {team.pool?.tournament_status || 'Unknown'}
              </div>
            </div>
            <div className="item-details">
              <span className="truncate-text">Team {team.id}</span>
              <span>Avg. Rank: {team.team_world_ranking_average || 'N/A'}</span>
            </div>
          </Link>
        ))}
      </div>
    );
  };

  const renderTournamentUpdates = () => {
    if (loading) return <div className="loading">Loading leaderboards...</div>;
    if (tournaments.length === 0) return <div className="empty-state">No leaderboards found</div>;

    return (
      <div className="dashboard-list">
        {tournaments.map(tournament => (
          <Link key={tournament.id} to={`/leaderboard/${tournament.id}`} className="dashboard-item">
            <div className="item-header">
              <h3 className="truncate-text">{tournament.name}</h3>
              <div className={`item-status ${getStatusClass(tournament.tournament_status)}`}>
                {tournament.tournament_status || 'Unknown'}
              </div>
            </div>
            <div className="item-details">
              <span>{tournament.number_of_teams || 0} Teams</span>
            </div>
          </Link>
        ))}
      </div>
    );
  };

  return (
    <div className="dashboard-container">
      <div className="welcome-card">
        <div className="dashboard-header">
          <h1>Welcome back, {userName || 'Golfer'}!</h1>
          <button onClick={() => setShowModal(true)} className="create-pool-button">
            <FaPlus /> New Pool
          </button>
        </div>
      </div>

      <div className="dashboard-grid">
        <div className="dashboard-section">
          <div className="section-header">
            <h2><FaUsers /> My Pools</h2>
            <Link to="/pools" className="view-all">View All</Link>
          </div>
          <div className="section-content pools-list">
            {renderPoolsList()}
          </div>
        </div>

        <div className="dashboard-section">
          <div className="section-header">
            <h2><IoGolfOutline /> My Teams</h2>
            <Link to="/teams" className="view-all">View All</Link>
          </div>
          <div className="section-content teams-list">
            {renderTeamsList()}
          </div>
        </div>

        <div className="dashboard-section">
          <div className="section-header">
            <h2><FaTrophy /> Leaderboards</h2>
            <Link to="/leaderboard" className="view-all">View All</Link>
          </div>
          <div className="section-content tournament-updates">
            {renderTournamentUpdates()}
          </div>
        </div>
      </div>

      {showModal && (
        <PoolDetailsModal
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          mode="insert"
          loggedInUser={Number(userId)}
        />
      )}
    </div>
  );
}

export default Dashboard; 