import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useFetchPoolAndLineItemData } from "./TeamHelper";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import { PacmanLoader } from "react-spinners";
import { IoChevronBackOutline } from "react-icons/io5";
import TeamSelectForm from "./TeamSelectForm";
import TeamViewForm from "./TeamViewForm";
import "./Team.css";
import { supabase } from "../Utilities/supabaseClient";
import moment from "moment-timezone";

const Team = () => {
  const { poolId, teamId } = useParams();
  const navigate = useNavigate();
  const [refetchTrigger, setRefetchTrigger] = useState(0);
  const [content, setContent] = useState(null);
  const [initialLoading, setInitialLoading] = useState(true);
  
  const { 
    poolData, 
    teamLineItem, 
    hasLineItems, 
    loading, 
    error, 
    isOwner,
    teamStatus 
  } = useFetchPoolAndLineItemData(
    poolId ? Number(poolId) : null,
    refetchTrigger,
    teamId ? Number(teamId) : null
  );

  useEffect(() => {
    if (!loading && poolData) {
      setInitialLoading(false);
    }
  }, [loading, poolData]);

  const [spinner, setSpinner] = useState(false);
  const [formError, setFormError] = useState("");
  const showError = error || formError;
  const isLoading = loading || spinner || initialLoading;

  const hasTournamentStarted = (pool) => {
    if (!pool?.first_tee_time || !pool?.time_zone) return false;
    
    const firstTeeTime = moment.tz(pool.first_tee_time, pool.time_zone);
    const now = moment().tz(pool.time_zone);
    
    return now.isAfter(firstTeeTime);
  };

  const renderContent = async () => {
    if (!poolData) {
      return (
        <div className="page-container">
          <div className="page-header">
            <div className="page-header-row">
              <button onClick={() => navigate(-1)} className="back-link">
                <IoChevronBackOutline /> Back
              </button>
              <div style={{ width: '80px' }}></div>
            </div>
            <p className="message">No pool data available.</p>
          </div>
        </div>
      );
    }

    const tournamentStarted = hasTournamentStarted(poolData);

    try {
      // Check if tournament field is set
      const { data: playerTournaments, error: playerTournamentsError } = await supabase
        .from("playersTournaments")
        .select("id")
        .eq("tournament", poolData.tournament_fixture.id)
        .limit(1);

      if (playerTournamentsError) {
        toast.error(`Error fetching tournament data: ${playerTournamentsError.message}`);
        throw playerTournamentsError;
      }

      if (!playerTournaments || playerTournaments.length === 0) {
        return (
          <div className="page-container">
            <div className="page-header">
              <div className="page-header-row">
                <button onClick={() => navigate(-1)} className="back-link">
                  <IoChevronBackOutline /> Back
                </button>
                <div style={{ width: '80px' }}></div>
              </div>
              <p className="message">⛳ The tournament field has not been set yet. Please check back on Monday of tournament week when the official field is announced. 🏌️</p>
            </div>
          </div>
        );
      }

      // If tournament has started, show team view form for everyone
      if (tournamentStarted) {
        if (hasLineItems && teamLineItem && teamLineItem.length > 0) {
          return (
            <div className="details-card">
              <TeamViewForm records={teamLineItem} isEditable={false} />
            </div>
          );
        }
        return (
          <div className="page-container">
            <div className="page-header">
              <div className="page-header-row">
                <button onClick={() => navigate(-1)} className="back-link">
                  <IoChevronBackOutline /> Back
                </button>
                <div style={{ width: '80px' }}></div>
              </div>
              <p className="message">No team data available for this tournament.</p>
            </div>
          </div>
        );
      }

      // Tournament hasn't started
      if (isOwner) {
        // Show TeamViewForm in editable mode if we have line items
        if (hasLineItems && teamLineItem && teamLineItem.length > 0) {
          return (
            <div className="details-card">
              <TeamViewForm 
                records={teamLineItem} 
                isEditable={true}
                poolData={poolData}
                setSpinner={setSpinner}
                setFormError={setFormError}
                setRefetchTrigger={setRefetchTrigger}
              />
            </div>
          );
        } else {
          // If no line items, show select form
          return (
            <div className="page-container">
              <TeamSelectForm 
                poolData={poolData} 
                setSpinner={setSpinner} 
                setFormError={setFormError} 
                setRefetchTrigger={setRefetchTrigger} 
              />
            </div>
          );
        }
      } else {
        // Non-owners can't view teams before tournament starts
        return (
          <div className="page-container">
            <div className="page-header">
              <div className="page-header-row">
                <button onClick={() => navigate(-1)} className="back-link">
                  <IoChevronBackOutline /> Back
                </button>
                <div style={{ width: '80px' }}></div>
              </div>
              <p className="message">Team details will be available after the tournament starts.</p>
            </div>
          </div>
        );
      }
    } catch (error) {
      toast.error(`Error checking tournament field: ${error.message}`);
      return (
        <div className="page-container">
          <div className="page-header">
            <div className="page-header-row">
              <button onClick={() => navigate(-1)} className="back-link">
                <IoChevronBackOutline /> Back
              </button>
              <div style={{ width: '80px' }}></div>
            </div>
            <p className="message">Error loading tournament data. Please try again later.</p>
          </div>
        </div>
      );
    }
  };

  useEffect(() => {
    const loadContent = async () => {
      if (!isLoading && !showError) {
        const renderedContent = await renderContent();
        setContent(renderedContent);
      }
    };
    loadContent();
  }, [poolData, teamLineItem, hasLineItems, isOwner, teamStatus, isLoading, showError]);

  // Show loading spinner while data is being fetched
  if (isLoading) {
    return (
      <div className="team-page">
        <div className="team-details-card">
          <div className="loading-team-overlay">
            <PacmanLoader className="spinner" color="#1a472a" loading={true} size={25} aria-label="Loading Spinner" />
          </div>
          <div className="team-page-header">
            <div className="team-page-header-row">
              <button onClick={() => navigate(-1)} className="back-link">
                <IoChevronBackOutline /> Back
              </button>
              <div style={{ width: '80px' }}></div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Show error if there is one
  if (showError) {
    return (
      <div className="team-page">
        <div className="team-page-header">
          <div className="team-page-header-row">
            <button onClick={() => navigate(-1)} className="back-link">
              <IoChevronBackOutline /> Back
            </button>
            <div style={{ width: '80px' }}></div>
          </div>
          <p className="message">{error?.message || formError || error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="pool-link-table-card">
      <ToastContainer 
        position="top-center" 
        autoClose={5000} 
        hideProgressBar={false} 
        newestOnTop={false} 
        closeOnClick 
        rtl={false} 
        pauseOnFocusLoss 
        draggable 
        pauseOnHover 
        theme="colored" 
      />
      <div className="pool-link-table-container">
        {content}
      </div>
    </div>
  );
};

export default Team;
